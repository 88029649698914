import { createContext, FC, useContext, PropsWithChildren, useEffect, useState, useRef, useMemo } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/system';
import {
    useGetInsightsOrganizationUserOutcomesQuery,
    useGetInsightsPersonalOutcomesQuery
} from '../services/InsightsQueryService';
import { IInsightsPersonalOutcomeVM } from '../interfaces/views/IInsightsPersonalOutcomeVM';
import useFilterSearch from '../hooks/useFilterSearch';
import { useInsightsPersonalStateValue } from './InsightsPersonalContext';
import { IScoreLine } from '../interfaces/IScoreLine';
import { IInsightsPersonalOutcomeGraphVM } from '../interfaces/views/IInsightsPersonalOutcomeGraphVM';
import { useInsightsStateValue } from './InsightsContext';
import { EInsightsMode } from '../interfaces/enums/EInsightsMode';
import expandDatesToLastYearPerWeek from '../utils/expandDatesToLastYearPerWeek';
import { ESetMasterLevelAction, usePostSetMasterLevelFocusToUser } from '../services/OutcomeQueryService';
import { EToastSeverity, useToastContextStateValue } from './ToastContext';
import { useApiStateValue } from './ApiContext';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';
import { insightsScoresPerDateSelector, selectScoreLinePerMonth } from '../utils/insightsGraphDataSelector';
import { useInsightsOrganizationStateValue } from './InsightsOrganizationContext';

export interface IInsightsPersonalOutcomesContext {
    data?: IInsightsPersonalOutcomeVM[];
    graphData: IInsightsPersonalOutcomeGraphVM[];
    searchText: string;
    setSearchText: (searchText: string) => void;
    scoreLines?: IScoreLine[];
    isLoading: boolean;
    isError: boolean;
    toggleMasterLevelToUserClickHandler: (outcomeId: number, action: ESetMasterLevelAction) => void;
    isPostSetMasterLevelFocusLoading: boolean;
}

export const InsightsPersonalOutcomesContext = createContext<IInsightsPersonalOutcomesContext>(
    {} as IInsightsPersonalOutcomesContext
);

export const InsightsPersonalOutcomesProvider: FC<PropsWithChildren> = ({ children }) => {
    const { id: paramsId } = useParams();
    const { mode } = useInsightsStateValue();
    const {
        data: fetchedData,
        isRefetching: isPersonalOutcomesRefetching,
        isFetching: isPersonalOutcomesFetching,
        refetch: refetchPersonalOutcomes,
        isError: isFetchPersonalOutcomesDataError
    } = useGetInsightsPersonalOutcomesQuery();
    const {
        data: fetchedOrganizationUserOutcomesData,
        refetch: refetchOrganizationUserOutcomes,
        isRefetching: isOrganizationUserOutcomesRefetching,
        isFetching: isOrganizationUserOutcomesFetching,
        isError: isFetchOrganizationUserOutcomesDataError
    } = useGetInsightsOrganizationUserOutcomesQuery(paramsId);
    const { mutateAsync: mutatePostSetMasterLevelFocus, isPending: isPostSetMasterLevelFocusLoading } =
        usePostSetMasterLevelFocusToUser();
    const { setToastMessage } = useToastContextStateValue();
    const { t } = useTranslation();

    const [data, setData] = useState<IInsightsPersonalOutcomeVM[]>([]);
    const [filteredData, setFilteredData] = useState<IInsightsPersonalOutcomeVM[] | undefined>();
    const [graphData, setGraphData] = useState<IInsightsPersonalOutcomeGraphVM[]>([]);
    const [scoreLines, setScoreLines] = useState<IScoreLine[] | undefined>();
    const scoreLinesAllOptionsRef = useRef<IScoreLine[][]>([]);
    const {
        getInsightsPersonalOutcomes,
        data: graphTotalData,
        selectedTimespanOption: selectedTimespanPersonalOption
    } = useInsightsPersonalStateValue();
    const { selectedTimespanOption: selectedTimespanOrganizationOption } = useInsightsOrganizationStateValue();
    const theme = useTheme();
    const { setUserName } = useInsightsStateValue();
    const { invalidateQueryCache } = useApiStateValue();

    const selectedTimespanOption = useMemo(() => {
        switch (mode) {
            case EInsightsMode.STANDARD:
                return selectedTimespanPersonalOption;
            case EInsightsMode.ORG_PERSONAL:
            case EInsightsMode.ORG_PERSONAL_OUTCOME_DETAILS:
                return selectedTimespanOrganizationOption;
            default:
                return undefined;
        }
    }, [mode, selectedTimespanPersonalOption, selectedTimespanOrganizationOption]);

    useEffect(() => {
        setData([]);
    }, [mode]);

    useEffect(() => {
        if (fetchedData) {
            setData(fetchedData);
            setFilteredData(fetchedData);
        }
    }, [fetchedData]);

    useEffect(() => {
        if (fetchedOrganizationUserOutcomesData) {
            setData(fetchedOrganizationUserOutcomesData.data);
            setFilteredData(fetchedOrganizationUserOutcomesData.data);
            setUserName(fetchedOrganizationUserOutcomesData.userName);
        }
    }, [fetchedOrganizationUserOutcomesData]);

    useEffect(() => {
        if (paramsId) {
            refetchOrganizationUserOutcomes();
            invalidateQueryCache(EApiQueryKey.INSIGHTS_FETCH_PERSONAL_OUTCOMES);
        } else {
            refetchPersonalOutcomes();
            invalidateQueryCache(EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_USER_OUTCOMES);
        }
    }, [paramsId]);

    useEffect(() => {
        if (graphTotalData && graphTotalData.length > 0) {
            const data = getInsightsPersonalOutcomes();
            setGraphData(data);

            const assignedScoreLine: IScoreLine = {
                id: 'W1',
                name: 'Assigned',
                color: theme.palette.status.assigned,
                scores: []
            };
            const inProgressScoreLine: IScoreLine = {
                id: 'W4',
                name: 'In Progress',
                color: theme.palette.status.inProgress,
                scores: []
            };
            const attainedScoreLine: IScoreLine = {
                id: 'W2',
                name: 'Attained',
                color: theme.palette.status.attained,
                scores: []
            };
            const needAttentionScoreLine: IScoreLine = {
                id: 'W3',
                name: 'Need Attention',
                color: theme.palette.status.needAttention,
                legendColor: '#ED8000',
                scores: []
            };
            data.forEach((dataItem) => {
                assignedScoreLine.scores.push({
                    date: dataItem.date,
                    value: dataItem.outcomesAssigned
                });
                inProgressScoreLine.scores.push({
                    date: dataItem.date,
                    value: dataItem.outcomesInProgress
                });
                attainedScoreLine.scores.push({
                    date: dataItem.date,
                    value: dataItem.outcomesAttained
                });
                needAttentionScoreLine.scores.push({
                    date: dataItem.date,
                    value: dataItem.outcomesNeedAttention
                });
            });
            const allIndividualScoreTimes = [
                expandDatesToLastYearPerWeek(assignedScoreLine),
                expandDatesToLastYearPerWeek(inProgressScoreLine),
                expandDatesToLastYearPerWeek(attainedScoreLine),
                expandDatesToLastYearPerWeek(needAttentionScoreLine)
            ];
            if (selectedTimespanOption) {
                const scoreLinesPerMonths = insightsScoresPerDateSelector(allIndividualScoreTimes);
                setScoreLines(selectScoreLinePerMonth(scoreLinesPerMonths, selectedTimespanOption));

                scoreLinesAllOptionsRef.current = scoreLinesPerMonths;
            }
        } else {
            setScoreLines([]);
            scoreLinesAllOptionsRef.current = [];
        }
    }, [graphTotalData, selectedTimespanOption]);

    const { searchText, setSearchText } = useFilterSearch<IInsightsPersonalOutcomeVM>({
        data: data,
        dataSerachablePropertyName: 'title',
        setDataCallback: setFilteredData
    });

    useEffect(() => {
        if (selectedTimespanOption)
            setScoreLines(selectScoreLinePerMonth(scoreLinesAllOptionsRef.current, selectedTimespanOption));
    }, [selectedTimespanOption]);

    const toggleMasterLevelToUserClickHandler = async (outcomeId: number, action: ESetMasterLevelAction) => {
        try {
            if (paramsId) {
                await mutatePostSetMasterLevelFocus({ outcomeId, action, targetUserId: paramsId });
                setData((data) => {
                    return data.map((item) => {
                        if (item.outcomeId === outcomeId) {
                            return {
                                ...item,
                                masterLevelRequired: action === ESetMasterLevelAction.ENABLE
                            };
                        }
                        return item;
                    });
                });
                if (action === ESetMasterLevelAction.ENABLE) {
                    setToastMessage({
                        isOpen: true,
                        message: t('success.insights.masterFocusSet'),
                        severity: EToastSeverity.SUCCESS
                    });
                } else {
                    setToastMessage({
                        isOpen: true,
                        message: t('success.insights.masterFocusRemove'),
                        severity: EToastSeverity.SUCCESS
                    });
                }
            }
        } catch (e) {
            console.error(e);
            setToastMessage({
                isOpen: true,
                message: t('errors.insights.masterFocusError'),
                severity: EToastSeverity.ERROR
            });
        }
    };

    const insightsPersonalOutcomesContext: IInsightsPersonalOutcomesContext = {
        data: filteredData,
        graphData,
        searchText,
        setSearchText,
        scoreLines,
        isLoading:
            mode === EInsightsMode.STANDARD
                ? isPersonalOutcomesFetching || isPersonalOutcomesRefetching
                : isOrganizationUserOutcomesFetching || isOrganizationUserOutcomesRefetching,
        isError:
            mode === EInsightsMode.STANDARD
                ? isFetchPersonalOutcomesDataError
                : isFetchOrganizationUserOutcomesDataError,
        toggleMasterLevelToUserClickHandler,
        isPostSetMasterLevelFocusLoading
    };

    return (
        <InsightsPersonalOutcomesContext.Provider value={insightsPersonalOutcomesContext}>
            {children}
        </InsightsPersonalOutcomesContext.Provider>
    );
};

export const useInsightsPersonalOutcomesStateValue: () => IInsightsPersonalOutcomesContext = () =>
    useContext(InsightsPersonalOutcomesContext);
