import { createContext, FC, useContext, PropsWithChildren, useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { SelectChangeEvent, useTheme } from '@mui/material';
import { IScoreLine } from '../interfaces/IScoreLine';
import { useGetAssessmentReportBySkillIdQuery } from '../services/AssessmentQueryService';
import { IAssessmentReportItemVM } from '../interfaces/views/IAssessmentReportItemVM';
import useDropdown from '../hooks/useDropdown';
import expandDatesToLastYearPerWeek from '../utils/expandDatesToLastYearPerWeek';
import { useApiStateValue } from './ApiContext';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';
import { insightsScoresPerDateSelector, selectScoreLinePerMonth } from '../utils/insightsGraphDataSelector';
import { useInsightsOrganizationStateValue } from './InsightsOrganizationContext';

export interface IGraphBenchmark {
    adoptionTarget: number;
    scoreTargetLow: number;
    scoreTargetHigh: number;
}

export interface IInsightsOrganizationAssessmentDetailsContext {
    data: any[];
    departments?: string[];
    scoreLines?: IScoreLine[];
    isAssessmentDataLoading: boolean;
    handleDepartmentFilterDropdownChange: (event: SelectChangeEvent<unknown>) => void;
    handleUserFilterDropdownChange: (event: SelectChangeEvent<unknown>) => void;
    selectedDepartmentFilterValue?: string;
    selectedUserFilterValue?: string;
    changeDepartmentFilterDropdownValue: (option: string) => void;
    skillName?: string;
    selectedGraphOption: EGraphOption;
    setSelectedGraphOption: (option: EGraphOption) => void;
    graphBenchmark?: IGraphBenchmark;
}

export enum EGraphOption {
    SCORE = 'Score',
    ADOPTION = 'Adoption'
}

export enum EUserType {
    ALL = 'All',
    BY_LICENSE = 'ByLicense'
}

export const InsightsOrganizationAssessmentDetailsContext =
    createContext<IInsightsOrganizationAssessmentDetailsContext>({} as IInsightsOrganizationAssessmentDetailsContext);

export const InsightsOrganizationalAssessmentDetailsProvider: FC<PropsWithChildren> = ({ children }) => {
    const [_, setData] = useState<IAssessmentReportItemVM[]>([]);
    const [filteredTableData, setFilteredTableData] = useState<IAssessmentReportItemVM[] | undefined>([]);
    const scoreLinesAllOptionsRef = useRef<IScoreLine[][]>([]);
    const [scoreLines, setScoreLines] = useState<IScoreLine[] | undefined>();
    const newestDateRef = useRef<Date>();
    const params = useParams();
    const {
        data: fetchedAssessmentData,
        isFetching: isAssessmentReportBySkillFetching,
        isLoading: isAssessmentReportBySkillLoading
    } = useGetAssessmentReportBySkillIdQuery(params.id);
    const {
        handleFilterDropdownChange: handleDepartmentFilterDropdownChange,
        value: selectedDepartmentFilterValue,
        changeFilterDropdownValue: changeDepartmentFilterDropdownValue
    } = useDropdown({});
    const { handleFilterDropdownChange: handleUserFilterDropdownChange, value: selectedUserFilterValue } = useDropdown({
        initialValue: EUserType.ALL
    });
    const [departments, setDepartments] = useState<string[] | undefined>();
    const [assessmentMapByDepartment, setAssessmentMapByDepartment] = useState<
        Map<string, IAssessmentReportItemVM[]> | undefined
    >();
    const [skillName, setSkillName] = useState<string | undefined>();
    const [selectedGraphOption, setSelectedGraphOption] = useState<EGraphOption>(EGraphOption.SCORE);
    const [graphBenchmark, setGraphBenchmark] = useState<IGraphBenchmark | undefined>();
    const theme = useTheme();
    const { invalidateQueryCache } = useApiStateValue();
    const { selectedTimespanOption } = useInsightsOrganizationStateValue();

    useEffect(() => {
        return () => {
            setScoreLines([]);
            setSkillName('');
            setData([]);
            invalidateQueryCache(EApiQueryKey.ASSESSMENT_FETCH_REPORT_BY_SKILL);
            setAssessmentMapByDepartment(undefined);
            scoreLinesAllOptionsRef.current = [];
            setFilteredTableData([]);
        };
    }, []);

    useEffect(() => {
        if (selectedDepartmentFilterValue && selectedUserFilterValue) {
            filterData();
        }
    }, [selectedDepartmentFilterValue, selectedUserFilterValue, selectedGraphOption, selectedTimespanOption]);

    const filterData = () => {
        if (selectedDepartmentFilterValue) {
            const departmentData = assessmentMapByDepartment?.get(selectedDepartmentFilterValue);
            const entireCompanyData = assessmentMapByDepartment?.get('Entire Company');
            setFilteredTableData(departmentData);

            if (!departmentData) {
                setScoreLines([]);
                return;
            }

            // ENTIRE COMPANY

            let scoreLineEntireCompany: IScoreLine = {
                color: theme.palette.primary.main,
                name: 'Entire Company',
                id: '1',
                type: EGraphOption.SCORE,
                subtype: EUserType.ALL,
                scores: []
            };

            let adoptionLineEntireCompany: IScoreLine = {
                color: theme.palette.primary.main,
                name: 'Entire Company',
                id: '2',
                type: EGraphOption.ADOPTION,
                subtype: EUserType.ALL,
                scores: []
            };

            let scoreLineEntireCompanyLicensed: IScoreLine = {
                color: theme.palette.primary.main,
                name: 'With Nulia License',
                id: '3',
                type: EGraphOption.SCORE,
                subtype: EUserType.BY_LICENSE,
                scores: []
            };

            let adoptionLineEntireCompanyLicensed: IScoreLine = {
                color: theme.palette.primary.main,
                name: 'With Nulia License',
                id: '4',
                type: EGraphOption.ADOPTION,
                subtype: EUserType.BY_LICENSE,
                scores: []
            };

            let scoreLineEntireCompanyUnlicensed: IScoreLine = {
                color: '#B09FDE',
                name: 'Without Nulia License',
                id: '5',
                type: EGraphOption.SCORE,
                subtype: EUserType.BY_LICENSE,
                scores: []
            };

            let adoptionLineEntireCompanyUnlicensed: IScoreLine = {
                color: '#B09FDE',
                name: 'Without Nulia License',
                id: '6',
                type: EGraphOption.ADOPTION,
                subtype: EUserType.BY_LICENSE,
                scores: []
            };

            // DEPARTMENT

            let scoreLineDepartment: IScoreLine = {
                color: theme.palette.status.attained,
                name: selectedDepartmentFilterValue || 'Department',
                id: '7',
                type: EGraphOption.SCORE,
                subtype: EUserType.ALL,
                scores: []
            };

            let adoptionLineDepartment: IScoreLine = {
                color: theme.palette.status.attained,
                name: selectedDepartmentFilterValue || 'Department',
                id: '8',
                type: EGraphOption.ADOPTION,
                subtype: EUserType.ALL,
                scores: []
            };

            let scoreLineDepartmentLicensed: IScoreLine = {
                color: theme.palette.primary.main,
                name: 'With Nulia License',
                id: '9',
                type: EGraphOption.SCORE,
                subtype: EUserType.BY_LICENSE,
                scores: []
            };

            let adoptionLineDepartmentLicensed: IScoreLine = {
                color: theme.palette.primary.main,
                name: 'With Nulia License',
                id: '10',
                type: EGraphOption.ADOPTION,
                subtype: EUserType.BY_LICENSE,
                scores: []
            };

            let scoreLineDepartmentUnlicensed: IScoreLine = {
                color: '#B09FDE',
                name: 'Without Nulia License',
                id: '11',
                type: EGraphOption.SCORE,
                subtype: EUserType.BY_LICENSE,
                scores: []
            };

            let adoptionLineDepartmentUnlicensed: IScoreLine = {
                color: '#B09FDE',
                name: 'Without Nulia License',
                id: '12',
                type: EGraphOption.ADOPTION,
                subtype: EUserType.BY_LICENSE,
                scores: []
            };

            let allIndividualScoreTimesEntireCompany: IScoreLine[] = [];
            let scoreLines4MonthEntireCompany: IScoreLine[] = [];
            let allIndividualScoreTimesDepartment: IScoreLine[] = [];
            let scoreLines4MonthDepartment: IScoreLine[] = [];

            entireCompanyData?.forEach(function (mapValue, mapKey) {
                let adoptionValue = 0;
                let scoreValue = 0;
                let adoptionValueLicensed = 0;
                let scoreValueLicensed = 0;
                let adoptionValueUnlicensed = 0;
                let scoreValueUnlicensed = 0;
                switch (selectedUserFilterValue) {
                    case EUserType.ALL:
                        adoptionValue = mapValue.adoptionAll;
                        scoreValue = mapValue.scoreAll;
                        break;
                    case EUserType.BY_LICENSE:
                        adoptionValueLicensed = mapValue.adoptionLicensed;
                        scoreValueLicensed = mapValue.scoreLicensed;
                        adoptionValueUnlicensed = mapValue.adoptionUnlicensed;
                        scoreValueUnlicensed = mapValue.scoreUnlicensed;
                        break;
                }

                scoreLineEntireCompany.scores.push({
                    date: mapValue.reportDate,
                    value: scoreValue
                });

                adoptionLineEntireCompany.scores.push({
                    date: mapValue.reportDate,
                    value: adoptionValue
                });

                scoreLineEntireCompanyLicensed.scores.push({
                    date: mapValue.reportDate,
                    value: scoreValueLicensed
                });

                scoreLineEntireCompanyUnlicensed.scores.push({
                    date: mapValue.reportDate,
                    value: scoreValueUnlicensed
                });

                adoptionLineEntireCompanyLicensed.scores.push({
                    date: mapValue.reportDate,
                    value: adoptionValueLicensed
                });

                adoptionLineEntireCompanyUnlicensed.scores.push({
                    date: mapValue.reportDate,
                    value: adoptionValueUnlicensed
                });

                allIndividualScoreTimesEntireCompany = [
                    expandDatesToLastYearPerWeek(scoreLineEntireCompany),
                    expandDatesToLastYearPerWeek(adoptionLineEntireCompany),
                    expandDatesToLastYearPerWeek(scoreLineEntireCompanyLicensed),
                    expandDatesToLastYearPerWeek(adoptionLineEntireCompanyLicensed),
                    expandDatesToLastYearPerWeek(scoreLineEntireCompanyUnlicensed),
                    expandDatesToLastYearPerWeek(adoptionLineEntireCompanyUnlicensed)
                ];
                scoreLines4MonthEntireCompany = allIndividualScoreTimesEntireCompany.map((scoreTime) => {
                    return {
                        ...scoreTime,
                        scores: scoreTime.scores.slice(-15)
                    };
                });
            });

            departmentData?.forEach(function (mapValue, mapKey) {
                let adoptionValue = 0;
                let scoreValue = 0;
                let adoptionValueLicensed = 0;
                let scoreValueLicensed = 0;
                let adoptionValueUnlicensed = 0;
                let scoreValueUnlicensed = 0;

                switch (selectedUserFilterValue) {
                    case EUserType.ALL:
                        adoptionValue = mapValue.adoptionAll;
                        scoreValue = mapValue.scoreAll;
                        break;
                    case EUserType.BY_LICENSE:
                        adoptionValueLicensed = mapValue.adoptionLicensed;
                        scoreValueLicensed = mapValue.scoreLicensed;
                        adoptionValueUnlicensed = mapValue.adoptionUnlicensed;
                        scoreValueUnlicensed = mapValue.scoreUnlicensed;
                        break;
                }

                scoreLineDepartment.scores.push({
                    date: mapValue.reportDate,
                    value: scoreValue
                });

                adoptionLineDepartment.scores.push({
                    date: mapValue.reportDate,
                    value: adoptionValue
                });

                scoreLineDepartmentLicensed.scores.push({
                    date: mapValue.reportDate,
                    value: scoreValueLicensed
                });

                scoreLineDepartmentUnlicensed.scores.push({
                    date: mapValue.reportDate,
                    value: scoreValueUnlicensed
                });

                adoptionLineDepartmentLicensed.scores.push({
                    date: mapValue.reportDate,
                    value: adoptionValueLicensed
                });

                adoptionLineDepartmentUnlicensed.scores.push({
                    date: mapValue.reportDate,
                    value: adoptionValueUnlicensed
                });

                allIndividualScoreTimesDepartment = [
                    expandDatesToLastYearPerWeek(scoreLineDepartment),
                    expandDatesToLastYearPerWeek(adoptionLineDepartment),
                    expandDatesToLastYearPerWeek(scoreLineDepartmentLicensed),
                    expandDatesToLastYearPerWeek(adoptionLineDepartmentLicensed),
                    expandDatesToLastYearPerWeek(scoreLineDepartmentUnlicensed),
                    expandDatesToLastYearPerWeek(adoptionLineDepartmentUnlicensed)
                ];
                scoreLines4MonthDepartment = allIndividualScoreTimesDepartment.map((scoreTime) => {
                    return {
                        ...scoreTime,
                        scores: scoreTime.scores.slice(-15)
                    };
                });
            });

            if (departmentData && departmentData?.length > 0)
                setGraphBenchmark({
                    adoptionTarget: departmentData[0].adoptionTarget,
                    scoreTargetLow: departmentData[0].scoreTargetLow,
                    scoreTargetHigh: departmentData[0].scoreTargetHigh
                });
            else if (entireCompanyData && entireCompanyData?.length > 0)
                setGraphBenchmark({
                    adoptionTarget: entireCompanyData[0].adoptionTarget,
                    scoreTargetLow: entireCompanyData[0].scoreTargetLow,
                    scoreTargetHigh: entireCompanyData[0].scoreTargetHigh
                });

            let allIndividualScoreTimes: IScoreLine[] = [];
            let scoreLines4Months: IScoreLine[] = [];
            if (selectedDepartmentFilterValue === 'Entire Company') {
                scoreLines4Months = [...scoreLines4MonthEntireCompany];
                allIndividualScoreTimes = [...allIndividualScoreTimesEntireCompany];
            } else if (selectedDepartmentFilterValue !== 'Entire Company') {
                allIndividualScoreTimes.unshift(...allIndividualScoreTimesDepartment);
                if (selectedUserFilterValue === EUserType.ALL)
                    allIndividualScoreTimes.unshift(...allIndividualScoreTimesEntireCompany);
                scoreLines4Months.unshift(...scoreLines4MonthDepartment);
                if (selectedUserFilterValue === EUserType.ALL)
                    scoreLines4Months.unshift(...scoreLines4MonthEntireCompany);
            }

            if (selectedTimespanOption) {
                const scoreLinesPerMonths = insightsScoresPerDateSelector(allIndividualScoreTimes);
                setScoreLines(selectScoreLinePerMonth(scoreLinesPerMonths, selectedTimespanOption));
                scoreLinesAllOptionsRef.current = scoreLinesPerMonths;
            }
        }
    };

    useEffect(() => {
        if (fetchedAssessmentData) {
            setData(fetchedAssessmentData);
            if (fetchedAssessmentData.length > 0) setSkillName(fetchedAssessmentData[0].skillName);
            const newestDate = fetchedAssessmentData.reduce((a, b) => {
                return a.reportDate > b.reportDate ? a : b;
            }).reportDate;
            newestDateRef.current = newestDate;
        }
    }, [fetchedAssessmentData]);

    useEffect(() => {
        const assessmentMap = new Map<string, IAssessmentReportItemVM[]>();
        if (fetchedAssessmentData) {
            fetchedAssessmentData.forEach((assessmentItem) => {
                const mapItem = assessmentMap.get(assessmentItem.department);
                if (mapItem) {
                    mapItem.push(assessmentItem);
                    assessmentMap.set(assessmentItem.department, mapItem);
                } else {
                    assessmentMap.set(assessmentItem.department, [assessmentItem]);
                }
            });
            const fetchedDepartments = Array.from(assessmentMap.keys());
            if (fetchedDepartments && fetchedDepartments.length > 0) {
                changeDepartmentFilterDropdownValue(fetchedDepartments[0]);
                setDepartments(fetchedDepartments);
            }
            setAssessmentMapByDepartment(assessmentMap);
        } else {
            setDepartments(undefined);
            setAssessmentMapByDepartment(undefined);
        }
    }, [fetchedAssessmentData]);

    useEffect(() => {
        setScoreLines(selectScoreLinePerMonth(scoreLinesAllOptionsRef.current, selectedTimespanOption));
    }, [selectedTimespanOption]);

    const insightsOrganizationAssessmentDetailsContext: IInsightsOrganizationAssessmentDetailsContext = {
        data: filteredTableData || [],
        isAssessmentDataLoading: isAssessmentReportBySkillLoading || isAssessmentReportBySkillFetching,
        scoreLines,
        changeDepartmentFilterDropdownValue,
        handleDepartmentFilterDropdownChange,
        handleUserFilterDropdownChange,
        departments,
        skillName,
        selectedGraphOption,
        setSelectedGraphOption,
        graphBenchmark,
        selectedUserFilterValue,
        selectedDepartmentFilterValue
    };

    return (
        <InsightsOrganizationAssessmentDetailsContext.Provider value={insightsOrganizationAssessmentDetailsContext}>
            {children}
        </InsightsOrganizationAssessmentDetailsContext.Provider>
    );
};

export const useInsightsOrganizationAssessmentDetailsStateValue: () => IInsightsOrganizationAssessmentDetailsContext =
    () => useContext(InsightsOrganizationAssessmentDetailsContext);
