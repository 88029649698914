import { createContext, FC, useContext, PropsWithChildren, useEffect, useState, useRef, useCallback } from 'react';
import { useTheme } from '@mui/system';
import { format, isBefore, parseISO } from 'date-fns';
import { useGetInsightsOrganizationEngagementPointsQuery } from '../services/InsightsQueryService';
import useFilterSearch from '../hooks/useFilterSearch';
import { IInsightsOrganizationEngagementVM } from '../interfaces/views/IInsightsOrganizationEngagementVM';
import { IFilterCategories, IFilterCategoryValue } from '../ui/filters/filters/Filters';
import {
    insightsOrganizationEngagementByDepartmentFilterCallback,
    insightsOrganizationEngagementByRegionFilterCallback,
    insightsOrganizationEngagementCategories
} from './util/filterCategories';
import { IBarChartItem } from '../interfaces/IBarChartItem';
import { IScoreLine } from '../interfaces/IScoreLine';
import { createMonthsArray } from '../utils/createMonthsArray';
import { isGraphMonthLabelMatch } from '../utils/isGraphMonthLabelMatch';
import expandDatesToLastYearPerWeek from '../utils/expandDatesToLastYearPerWeek';
import { insightsScoresPerDateSelector, selectScoreLinePerMonth } from '../utils/insightsGraphDataSelector';
import { useInsightsOrganizationStateValue } from './InsightsOrganizationContext';

export enum EScoreLineType {
    ENGAGEMENT = 'Engagement',
    LOGIN = 'Logins'
}

export interface IInsightsOrganizationEngagementContext {
    data: IInsightsOrganizationEngagementVM[];
    latestDateData: IInsightsOrganizationEngagementVM[];
    searchText: string;
    setSearchText: (searchText: string) => void;
    filterCategories: IFilterCategories[];
    onFilterValueChange: (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => void;
    engagementGraphData?: IBarChartItem[];
    loggedInGraphData?: IBarChartItem[];
    isEngagementDataLoading: boolean;
    engagementMapByUsers: Map<string, IInsightsOrganizationEngagementVM[]>;
}

export const InsightsOrganizationEngagementContext = createContext<IInsightsOrganizationEngagementContext>(
    {} as IInsightsOrganizationEngagementContext
);

export enum EOrganizationEngagementScoreLineType {
    LOGINS = 'Login',
    ENGAGEMENT_POINTS = 'EngagementPoints'
}

export const InsightsOrganizationEngagementProvider: FC<PropsWithChildren> = ({ children }) => {
    // Data used for Insights Organizational Engagement graph
    const [data, setData] = useState<IInsightsOrganizationEngagementVM[]>([]);
    const [filteredData, setFilteredData] = useState<IInsightsOrganizationEngagementVM[]>([]);
    // ---

    // Data used for Insights Organizational Engagement table
    const [latestDateData, setLatestDateData] = useState<IInsightsOrganizationEngagementVM[]>([]);
    const [filteredLatestDateData, setFilteredLatestDateData] = useState<IInsightsOrganizationEngagementVM[]>([]);
    // ---

    const [filterCategories, setFilterCategories] = useState<IFilterCategories[]>(
        insightsOrganizationEngagementCategories
    );
    const { data: engagementData, isLoading: isEngagementDataLoading } =
        useGetInsightsOrganizationEngagementPointsQuery();
    const [filteredEngagementMapByDate, setFilteredEngagementMapByDate] = useState<
        Map<string, IInsightsOrganizationEngagementVM[]>
    >(new Map());
    const [engagementMapByUsers, setEngagementMapByUsers] = useState<Map<string, IInsightsOrganizationEngagementVM[]>>(
        new Map()
    );
    const [activeFilters, setActiveFilters] = useState<string[]>([]);
    const newestDateRef = useRef<Date>();
    const [engagementGraphData, setEngagementGraphData] = useState<IBarChartItem[] | undefined>();
    const [loggedInGraphData, setLoggedInGraphData] = useState<IBarChartItem[] | undefined>();
    const scoreLinesAllOptionsRef = useRef<IScoreLine[][] | undefined>();
    const theme = useTheme();
    const { selectedTimespanOption } = useInsightsOrganizationStateValue();

    const generateMapByDateBasedOnData: (
        data: IInsightsOrganizationEngagementVM[]
    ) => Map<string, IInsightsOrganizationEngagementVM[]> | undefined = (data) => {
        if (data) {
            const competenceMapByDate = new Map();
            data.forEach((item) => {
                if (item.date && competenceMapByDate.has(item.date.toISOString())) {
                    competenceMapByDate.set(item.date.toISOString(), [
                        ...competenceMapByDate.get(item.date.toISOString()),
                        item
                    ]);
                } else {
                    competenceMapByDate.set(item.date.toISOString(), [item]);
                }
            });
            return competenceMapByDate;
        }
        return;
    };

    const generateMapByUserBasedOnData: (
        data: IInsightsOrganizationEngagementVM[]
    ) => Map<string, IInsightsOrganizationEngagementVM[]> | undefined = (data) => {
        if (data) {
            const competenceMapByUser = new Map();
            data.forEach((item) => {
                if (item.userId && competenceMapByUser.has(item.userId)) {
                    competenceMapByUser.set(item.userId, [...competenceMapByUser.get(item.userId), item]);
                } else {
                    competenceMapByUser.set(item.userId, [item]);
                }
            });
            return competenceMapByUser;
        }
        return;
    };

    useEffect(() => {
        if (filteredData) {
            const filteredUserIdList = filteredData.map((item) => item.userId);
            setFilteredLatestDateData(
                latestDateData
                    .sort((a, b) => (isBefore(a.date as Date, b.date as Date) ? -1 : 1))
                    .filter((dataItem) => {
                        return filteredUserIdList.includes(dataItem.userId);
                    })
            );
            const competenceMapByDate = generateMapByDateBasedOnData(
                filteredData.sort((a, b) => (isBefore(a.date as Date, b.date as Date) ? -1 : 1))
            );

            const competenceMapByUsers = generateMapByUserBasedOnData(
                filteredData.sort((a, b) => (isBefore(a.date as Date, b.date as Date) ? -1 : 1))
            );

            if (competenceMapByDate) {
                setFilteredEngagementMapByDate(competenceMapByDate);
            }
            if (competenceMapByUsers) {
                setEngagementMapByUsers(competenceMapByUsers);
            }
        }
    }, [filteredData]);

    useEffect(() => {
        if (engagementData) {
            const departments = new Set<string>();
            const regions = new Set<string>();

            engagementData.forEach((dataItem) => {
                if (dataItem.department) departments.add(dataItem.department);
                if (dataItem.region) regions.add(dataItem.region);
            });

            filterCategories.forEach((filterCategory) => {
                if (filterCategory.radioGroupId === 'department') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    departments.forEach((department) => {
                        categoryValues.push({
                            key: department,
                            name: department,
                            callback: insightsOrganizationEngagementByDepartmentFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
                if (filterCategory.radioGroupId === 'region') {
                    const categoryValues: IFilterCategoryValue[] = [];
                    regions.forEach((region) => {
                        categoryValues.push({
                            key: region,
                            name: region,
                            callback: insightsOrganizationEngagementByRegionFilterCallback
                        });
                    });
                    filterCategory.values = categoryValues;
                }
            });
            setFilterCategories([...filterCategories]);
        }
    }, [engagementData]);

    useEffect(() => {
        if (filteredEngagementMapByDate && filteredEngagementMapByDate.size > 0) {
            const engagementScoreLine: IScoreLine = {
                id: 'EW1',
                name: 'Engagement',
                color: theme.palette.status.assigned,
                type: EScoreLineType.ENGAGEMENT,
                scores: []
            };

            const loginScoreLine: IScoreLine = {
                id: 'LW2',
                name: 'Logins',
                color: theme.palette.status.assigned,
                type: EScoreLineType.LOGIN,
                scores: []
            };

            let engagementScoreLineYear: IScoreLine = {
                ...engagementScoreLine,
                id: 'EY1',
                type: EScoreLineType.ENGAGEMENT,
                scores: createMonthsArray()
            };

            let loginScoreLineYear: IScoreLine = {
                ...loginScoreLine,
                id: 'LY2',
                type: EScoreLineType.LOGIN,
                scores: createMonthsArray()
            };

            filteredEngagementMapByDate.forEach(function (mapValue, mapKey) {
                let engagementPerDateTotal = 0;
                let loginPerDateTotal = 0;

                mapValue.forEach((mapItem) => {
                    engagementPerDateTotal += mapItem.pointsTotal;
                    loginPerDateTotal += mapItem.loginCount;
                });

                engagementScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: engagementPerDateTotal
                });

                loginScoreLine.scores.push({
                    date: mapValue[0].date,
                    value: loginPerDateTotal
                });

                const engagementScoreLineMonth = engagementScoreLineYear.scores.find((scoreLineYear) => {
                    return isGraphMonthLabelMatch(mapValue[0].date, scoreLineYear.date);
                });
                const loginScoreLineMonth = loginScoreLineYear.scores.find((scoreLineYear) => {
                    return isGraphMonthLabelMatch(mapValue[0].date, scoreLineYear.date);
                });

                mapValue.forEach((dataItem) => {
                    if (engagementScoreLineMonth) {
                        if (!engagementScoreLineMonth?.value) engagementScoreLineMonth.value = 0;
                        engagementScoreLineMonth.value = engagementScoreLineMonth.value + dataItem.pointsTotal;
                    }

                    if (loginScoreLineMonth) {
                        if (!loginScoreLineMonth?.value) loginScoreLineMonth.value = 0;
                        loginScoreLineMonth.value = loginScoreLineMonth.value + dataItem.loginCount;
                    }
                });
                const allIndividualScoreTimes = [
                    expandDatesToLastYearPerWeek(engagementScoreLine),
                    expandDatesToLastYearPerWeek(loginScoreLine)
                ];
                const scoreLinesPerMonths = insightsScoresPerDateSelector(allIndividualScoreTimes);

                scoreLinesAllOptionsRef.current = scoreLinesPerMonths;

                filterScoreLines();
            });
        } else {
            setEngagementGraphData(undefined);
            setLoggedInGraphData(undefined);
            scoreLinesAllOptionsRef.current = undefined;
        }
    }, [filteredEngagementMapByDate]);

    useEffect(() => {
        if (filteredEngagementMapByDate && filteredEngagementMapByDate.size > 0) {
            const engagementGraphDataCalc: IBarChartItem[] = [];
            const loggedInGraphDataCalc: IBarChartItem[] = [];

            filteredEngagementMapByDate.forEach((obj, key) => {
                const name = format(parseISO(key), 'MM/dd');
                let loginCountTotal = 0;
                let engagementCountTotal = 0;
                obj.forEach((d) => {
                    loginCountTotal += d.loginCount;
                    engagementCountTotal += d.pointsTotal;
                });
                engagementGraphDataCalc.push({
                    name,
                    value: engagementCountTotal
                });
                loggedInGraphDataCalc.push({
                    name,
                    value: loginCountTotal
                });
            });
        }
    }, [filteredEngagementMapByDate]);

    const filterScoreLines = useCallback(() => {
        if (!scoreLinesAllOptionsRef.current) return;
        let chosenScoreLine;
        chosenScoreLine = selectScoreLinePerMonth(scoreLinesAllOptionsRef.current, selectedTimespanOption!);

        setEngagementGraphData(
            chosenScoreLine
                ?.find((scoreLine) => scoreLine?.type === EScoreLineType.ENGAGEMENT)
                ?.scores.map((item) => {
                    return {
                        name: typeof item.date === 'string' ? item.date : format(item.date as Date, 'MM/dd'),
                        value: item.value
                    };
                }) || []
        );
        setLoggedInGraphData(
            chosenScoreLine
                ?.find((scoreLine) => scoreLine?.type === EScoreLineType.LOGIN)
                ?.scores.map((item) => {
                    return {
                        name: typeof item.date === 'string' ? item.date : format(item.date as Date, 'MM/dd'),
                        value: item.value
                    };
                }) || []
        );
    }, [selectedTimespanOption]);

    useEffect(() => {
        filterScoreLines();
    }, [selectedTimespanOption]);

    const onAnythingChange: any = useCallback(
        (organizationEngagementDataNeedToBeFiltered: IInsightsOrganizationEngagementVM[], runSearchText?: boolean) => {
            let newFilteredEngagementData = [...organizationEngagementDataNeedToBeFiltered];

            if (runSearchText) {
                newFilteredEngagementData = instantFilterByText(searchText, newFilteredEngagementData);
                setFilteredData(newFilteredEngagementData);
            } else {
                onFilterValueChange(filterCategories, activeFilters, true);
                setFilteredData(newFilteredEngagementData);
            }
            return newFilteredEngagementData;
        },
        [
            engagementData,
            // @ts-ignore
            searchText,
            // @ts-ignore
            activeFilters,
            filterCategories
        ]
    );

    useEffect(() => {
        if (engagementData) {
            setData(engagementData);
            setFilteredData(engagementData);
            const newestDate = engagementData.reduce(
                (a, b) => {
                    return a.date > b.date ? a : b;
                },
                {
                    date: new Date(1970, 1, 1)
                }
            ).date;
            newestDateRef.current = newestDate;
            const latestDataItems = engagementData.filter((dataItem) => {
                if (dataItem.date.getTime() === newestDate.getTime()) return true;
                return false;
            });
            setLatestDateData(latestDataItems);
            setFilteredLatestDateData(latestDataItems);
        }
    }, [engagementData]);

    const onFilterValueChange = (
        filterCategories: IFilterCategories[],
        activeFilters: string[],
        dontRunAnythingChange?: boolean
    ) => {
        setActiveFilters(activeFilters);
        let newFilteredData = [...data];
        filterCategories.forEach((filterCategory) => {
            if (filterCategory.values) {
                filterCategory.values!.forEach((filterCategoryValue) => {
                    if (filterCategoryValue.callback && activeFilters.includes(filterCategoryValue.key)) {
                        newFilteredData = newFilteredData.filter((dataItem) => {
                            if (filterCategoryValue.callback) {
                                const isValid = filterCategoryValue.callback(
                                    dataItem,
                                    filterCategoryValue.name,
                                    filterCategoryValue.key
                                );
                                return isValid;
                            }
                            return false;
                        });
                    }
                });
            }
        });
        if (!dontRunAnythingChange) onAnythingChange(newFilteredData, true);
    };

    const { searchText, setSearchText, instantFilterByText } = useFilterSearch<IInsightsOrganizationEngagementVM>({
        data: data,
        dataSerachablePropertyName: 'displayName',
        setDataCallback: setFilteredData,
        onChangeCallback: onAnythingChange
    });

    const insightsOrganizationEngagementContext: IInsightsOrganizationEngagementContext = {
        data: filteredData,
        latestDateData: filteredLatestDateData,
        searchText,
        setSearchText,
        filterCategories,
        onFilterValueChange,
        isEngagementDataLoading,
        loggedInGraphData,
        engagementGraphData,
        engagementMapByUsers
    };

    return (
        <InsightsOrganizationEngagementContext.Provider value={insightsOrganizationEngagementContext}>
            {children}
        </InsightsOrganizationEngagementContext.Provider>
    );
};

export const useInsightsOrganizationEngagementStateValue: () => IInsightsOrganizationEngagementContext = () =>
    useContext(InsightsOrganizationEngagementContext);
