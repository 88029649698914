import { FC, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { useTheme } from '@mui/system';
import { ESkillStatus } from '../../../interfaces/enums/ESkillStatus';
import { detectBrowser } from '../../../utils/detectBrowser';
import { EBrowser } from '../../../interfaces/enums/EBrowser';
import { isElementInViewport } from '../../../utils/isElementInViewport';

interface IProps {
    achievedStepNumber: number;
    totalStepNumber: number;
    dimension?: IDimension;
    isAssigned?: boolean;
    skillStatus?: ESkillStatus;
    index?: number;
    noWaitingForAnimation?: boolean;
    isDataMissing: boolean;
}

interface IDimension {
    width?: number;
    height?: number;
    margin?: {
        right?: number;
        left?: number;
        bottom?: number;
        top?: number;
    };
    starSize?: number;
    starX?: number;
    starY?: number;
}

const defaultDimension: IDimension = {
    width: 400,
    height: 300,
    margin: {
        right: 20,
        left: 40,
        bottom: 50,
        top: 20
    },
    starSize: 26,
    starX: -5,
    starY: -7
};

const emptyChartColor = '#E5E5E5';

interface Datum {
    type: string;
    value: number;
    color: string;
}

const BestPracticeProgressDoughnutChart: FC<IProps> = ({
    achievedStepNumber,
    totalStepNumber,
    dimension,
    skillStatus,
    index,
    isAssigned,
    noWaitingForAnimation = false,
    isDataMissing
}) => {
    const graphBoxRef = useRef<any>(null);
    const idRef = useRef(Math.floor(Math.random() * 1000000).toString());
    const svgRef = useRef<any>(null);
    const currentArcRef = useRef<any>(null);
    const theme = useTheme();

    const [scrolling, setScrolling] = useState<boolean>(true);
    const [isRendered, setRendered] = useState<boolean>(false);

    useEffect(() => {
        const onScroll = (e: any) => {
            if (isDataMissing) return;
            if (graphBoxRef.current) {
                // Only completely visible elements return true:
                var isVisible = isElementInViewport(graphBoxRef.current);

                // Partially visible elements return true:
                //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
                const mainGElement = svgRef.current.select('#main-g');
                if (
                    (isVisible && mainGElement && currentArcRef.current && scrolling) ||
                    (noWaitingForAnimation && scrolling)
                ) {
                    let arcFillColor = theme.palette.gray.light;
                    if (isAssigned) {
                        if (achievedStepNumber === totalStepNumber) arcFillColor = theme.palette.status.attained;
                        else arcFillColor = theme.palette.progressIndicators.warning;
                    }

                    const innerChartData = [
                        {
                            type: 'Current',
                            value: achievedStepNumber,
                            color: arcFillColor
                        },
                        {
                            type: 'Total',
                            value: totalStepNumber - achievedStepNumber,
                            color: '#E5E5E5'
                        }
                    ];

                    const currentPieData = d3
                        .pie<Datum>()
                        .sort(null)
                        .value((d) => d.value)(innerChartData);

                    const finalDimensions = {
                        ...defaultDimension,
                        ...dimension,
                        margin: {
                            ...defaultDimension.margin,
                            ...dimension?.margin
                        }
                    };

                    let r1 = (0.4 * finalDimensions.height!) / 2;
                    let r2 = (0.85 * finalDimensions.height!) / 2;

                    const currentArc = d3
                        .arc<d3.PieArcDatum<Datum>>()
                        .innerRadius(r1 + 1)
                        .outerRadius(r2 - 1);

                    const easeInverse = (ease: any) => {
                        return function (e: any) {
                            var min = 0,
                                max = 1;
                            while (max - min > 1e-3) {
                                var mid = (max + min) * 0.5;
                                let emid = ease(mid);
                                if (emid > e) {
                                    max = mid;
                                } else {
                                    min = mid;
                                }
                            }
                            return max;
                        };
                    };

                    var inverseCubic = easeInverse(d3.easeCubic);
                    var oneOver2Pi = 1.0 / (2 * Math.PI);
                    var total_msec = 1000;

                    svgRef.current
                        .append('g')
                        .attr('class', 'donut-container')
                        .attr('transform', `translate(${finalDimensions.width! / 2}, ${finalDimensions.height! / 2})`)
                        .selectAll('path')
                        .data(currentPieData)
                        .join('path')
                        .style('stroke', theme.palette.common.black)
                        .style('stroke-width', 0)
                        .style('fill', (d: { data: { color: any } }) => d.data.color)
                        .attr('d', currentArc)
                        .attr('id', 'main-g')
                        .transition()
                        .ease(d3.easeLinear)
                        .delay(0)
                        .duration(function (d: { endAngle: number; startAngle: number }) {
                            return (
                                total_msec *
                                (inverseCubic(d.endAngle * oneOver2Pi) - inverseCubic(d.startAngle * oneOver2Pi))
                            );
                        })
                        .attrTween('d', function (d: any) {
                            var start = { startAngle: 0, endAngle: 0 };
                            var interpolate = d3.interpolate(start, d);
                            return function (t: number) {
                                if (currentArcRef.current) return currentArcRef.current(interpolate(t)) + '';
                                return '';
                            };
                        });

                    if (isAssigned) {
                        svgRef.current
                            .append('line')
                            .attr('id', `line-${idRef.current}`)
                            .attr('x2', (finalDimensions.margin.left! + finalDimensions.width!) / 2)
                            .attr('y2', r2 - r1 + 5)
                            .attr('x1', (finalDimensions.margin.left! + finalDimensions.width!) / 2)
                            .attr('y1', finalDimensions.margin.top)
                            .style('stroke-width', 1)
                            .style('stroke', theme.palette.common.black)
                            .style('fill', theme.palette.common.black)
                            .style('transform', 'translate(-5px, 0)')
                            .style('opacity', '0.9');
                    }

                    setScrolling(false);
                }
            }
        };
        window.addEventListener('scroll', onScroll);
        if (isRendered) onScroll(null);

        return () => window.removeEventListener('scroll', onScroll);
    }, [scrolling, isRendered, isAssigned, noWaitingForAnimation, index, isDataMissing]);

    useEffect(() => {
        if (isDataMissing) return;
        d3.selectAll(graphBoxRef.current).remove();
        d3.selectAll('#graph-svg' + idRef.current).remove();
        d3.selectAll(`#line-${idRef.current}`).remove();
        setScrolling(true);

        const finalDimensions = {
            ...defaultDimension,
            ...dimension,
            margin: {
                ...defaultDimension.margin,
                ...dimension?.margin
            }
        };

        let r1 = (0.4 * finalDimensions.height!) / 2;
        let r2 = (0.85 * finalDimensions.height!) / 2;

        const browserType = detectBrowser();

        let svg = d3
            .select(graphBoxRef.current)
            .append('svg')
            // .style('padding-top', '10px')
            .attr('id', 'graph-svg' + idRef.current)
            .attr('width', finalDimensions.width! + finalDimensions.margin?.left! + finalDimensions.margin?.right!)
            .attr('height', finalDimensions.height! + finalDimensions.margin!.top! + finalDimensions.margin?.bottom!)
            .style('fill', 'rgba(229, 229, 229, 1)')
            .style(
                'filter',
                browserType !== EBrowser.FIREFOX && browserType !== EBrowser.SAFARI ? 'url(#drop-shadow)' : ''
            )
            .append('g')
            .attr('id', `doughnut-chart-${index ?? 0}`);

        svgRef.current = svg;

        let defs = svg.append('defs');
        let filter = defs.append('filter').attr('id', 'drop-shadow');
        filter.append('feGaussianBlur').attr('in', 'FillPaint').attr('stdDeviation', 3).attr('result', 'blur');
        filter
            .append('feOffset')
            .attr('blur', 3)
            .attr('in', 'blur')
            .attr('dx', 1)
            .attr('dy', 1)
            .attr('result', 'offsetBlur');
        filter.append('feFlood').attr('result', 'offsetColor').attr('floodOpacity', 0.5).attr('floodColor', '#F00');
        filter
            .append('feComposite')
            .attr('in', 'offsetColor')
            .attr('in2', 'offsetBlur')
            .attr('operator', 'in')
            .attr('result', 'offsetBlur');

        let feMerge = filter.append('feMerge');

        feMerge.append('feMergeNode').attr('in', 'offsetBlur');
        feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

        const emptyChartData = [
            {
                type: 'Total',
                value: totalStepNumber,
                color: emptyChartColor
            }
        ];

        const emptyPieData = d3
            .pie<Datum>()
            .sort(null)
            .value((d) => d.value)(emptyChartData);

        const emptyArc = d3.arc<d3.PieArcDatum<Datum>>().innerRadius(r1).outerRadius(r2);

        svg.append('g')
            .attr('class', 'donut-container')
            .attr('transform', `translate(${finalDimensions.width! / 2}, ${finalDimensions.height! / 2})`)
            .selectAll('path')
            .data(emptyPieData)
            .join('path')
            .style('stroke', theme.palette.common.black)
            .style('stroke-width', 2)
            .attr('d', emptyArc)
            .style('fill', emptyChartColor);

        const currentArc = d3
            .arc<d3.PieArcDatum<Datum>>()
            .innerRadius(r1 + 1)
            .outerRadius(r2 - 1);

        currentArcRef.current = currentArc;

        svg.selectAll('circle')
            .data([1])
            .enter()
            .append('circle')
            .style('stroke-width', 0)
            .style('fill', '#FFF')
            .attr('id', 'colored-fill')
            .attr('r', (0.4 * finalDimensions.height!) / 2 - 1)
            .attr('cx', finalDimensions.width! / 2)
            .attr('cy', finalDimensions.height! / 2);

        let translateLeftPX = 0;
        let translateTopPX = 0;
        let translateBaseLeftPX = finalDimensions.width! - finalDimensions.margin.left!;
        let translateBaseTopPX = finalDimensions.height! + finalDimensions.margin.top!;

        let fontSize = 14;
        if (totalStepNumber < 10) {
            translateLeftPX = translateBaseLeftPX - 12;
            translateTopPX = translateBaseTopPX + 1;
        } else {
            if (achievedStepNumber < 10) {
                fontSize = 13;
                translateLeftPX = translateBaseLeftPX - 17;
                translateTopPX = translateBaseTopPX + 1;
            } else {
                fontSize = 10;
                translateLeftPX = translateBaseLeftPX - 18;
                translateTopPX = translateBaseTopPX - 1;
            }
        }
        svg.append('g')
            .attr('id', 'value')
            .attr('transform', `translate(${translateLeftPX / 2}, ${translateTopPX / 2})`)
            .attr('font-size', fontSize)
            .attr('font-weight', browserType !== EBrowser.SAFARI ? 700 : 400)
            .attr('color', isAssigned ? '#352957' : '#8C8C8C')
            .append('text')
            .text(`${achievedStepNumber}/${totalStepNumber}`)
            .attr('fill', 'rgba(53, 41, 87, 1)')
            .style('letter-spacing', '0.25px');

        setRendered(true);
    }, [achievedStepNumber, totalStepNumber, skillStatus, isAssigned, isDataMissing]);

    useEffect(() => {
        if (isDataMissing) return;
        svgRef.current.select('#value text').attr('fill', isAssigned ? '#352957' : '#8C8C8C');
    }, [isAssigned, isDataMissing]);

    return (
        <div className='best-practice-icon' ref={graphBoxRef} style={{ position: 'relative', minWidth: '100px' }}>
            {isDataMissing && (
                <div
                    style={{
                        borderRadius: 34,
                        border: '2px solid black',
                        height: '68px',
                        width: '68px',
                        marginLeft: '6px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '12px',
                        backgroundColor: 'rgb(229, 229, 229)'
                    }}
                >
                    No Data
                </div>
            )}
        </div>
    );
};

export default BestPracticeProgressDoughnutChart;
