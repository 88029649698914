import { FormControl, MenuItem, Paper, Select, Typography } from '@mui/material';
import styled from '../../../../../ui/theme/styled';
import { Download } from 'react-feather';

export const RootPaper = styled(Paper)(({ theme }) => ({
    width: '100%',
    minHeight: '515px',
    [theme.breakpoints.down('lg')]: {
        minHeight: '495px'
    }
}));

export const GraphWrapper = styled('div', {
    shouldForwardProp: (prop) => prop !== 'height'
})<{ height?: string }>(({ theme, height }) => ({
    flex: 2,
    padding: '0 10px',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
        overflowX: 'scroll'
    },
    [theme.breakpoints.down('lg')]: {
        padding: 0
    },
    minHeight: height || 'unset',
    display: 'flex',
    alignItems: 'center'
}));

export const CardHeader = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isCustomToolbar' && prop !== 'isColumnDirectionNeeded'
})<{ isCustomToolbar?: boolean; isColumnDirectionNeeded?: boolean }>(
    ({ theme, isCustomToolbar, isColumnDirectionNeeded = false }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '9px 25px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.16)',
        fontSize: 18,
        fontWeight: 400,
        fontFamily: 'Segoe UI',
        lineHeight: '22px',
        color: 'rgba(0, 0, 0, 0.88)',
        [theme.breakpoints.down('lg')]: {
            borderBottom: 'none',
            fontSize: '14px',
            letterSpacing: '0.1px',
            paddingLeft: isCustomToolbar ? '10px' : '40px',
            paddingRight: isCustomToolbar ? '10px' : '25px',
            flexWrap: 'wrap',
            width: '100%',
            justifyContent: 'space-between'
        },
        [theme.breakpoints.down(800)]: {
            flexDirection: isColumnDirectionNeeded ? 'column' : 'row',
            alignItems: isColumnDirectionNeeded ? 'center' : 'initial'
        },
        [theme.breakpoints.down(660)]: {
            flexDirection: 'column',
            alignItems: 'center'
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: isCustomToolbar ? '10px' : '20px',
            paddingRight: isCustomToolbar ? '10px' : '15px',
            justifyContent: 'center'
        }
    })
);

export const GraphCardHeaderTitleTypography = styled(Typography)(({ theme }) => ({
    display: 'flex',
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down(700)]: {
        width: '100%'
    }
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: 210,
    display: 'flex',
    margin: 1,
    [theme.breakpoints.down('lg')]: {
        width: '120px'
    },
    [theme.breakpoints.down('md')]: {
        marginRight: '5px',
        width: '150px',
        justifyContent: 'center'
    },
    '& .MuiSelect-select:focus': {
        outline: 'black auto 1px !important'
    }
}));

export const StyledSelect = styled(Select<string[]>)(({ theme }) => ({
    '.MuiSvgIcon-root': {
        top: 3
    },
    '.MuiSelect-select': {
        outline: '0 !important',
        width: '210px',
        overflow: 'hidden',
        display: 'inline-block !important',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        position: 'relative',
        top: '2px',
        [theme.breakpoints.down('lg')]: {
            fontSize: '14px !important',
            width: '120px'
        }
    },
    '.MuiSelect-icon': {
        transform: 'rotate(180deg)'
    },
    '.MuiSelect-iconOpen': {
        transform: 'rotate(0deg)'
    }
}));

export const StyledMenuItem = styled(MenuItem)({});

export const MenuItemTitleSpan = styled('span')({
    paddingLeft: 6,
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.5px'
});

export const DownloadReportBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px',
    gap: 12,
    [theme.breakpoints.down('lg')]: {
        justifyContent: 'flex-end'
    },
    [theme.breakpoints.down('md')]: {
        // width: '100%'
    }
}));

export const DownloadIconBox = styled('div')<{ isDisabled?: boolean }>(({ isDisabled }) => ({
    padding: '4px',
    '&:hover': !isDisabled && {
        cursor: 'pointer',
        background: '#F5F5F5',
        borderRadius: 12
    }
}));

export const DownloadSelectBox = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('lg')]: {
        flex: 2,
        flexWrap: 'no-wrap'
    },
    [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-end'
    },
    [theme.breakpoints.down(440)]: {
        width: '100%',
        justifyContent: 'center'
    }
}));

export const StyledDownload = styled(Download)<{ isDisabled?: boolean }>(({ isDisabled }) => ({
    color: isDisabled ? 'rgba(0, 0, 0, 0.2)' : '#654ea3'
}));
