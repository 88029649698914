import { Order } from '../hooks/useTable';
import { isBefore, parse } from 'date-fns';

const sortPossibleUndefinedDates = (a: string | Date | undefined, b: string | Date | undefined, order: Order) => {
    let aDate = a;
    let bDate = b;
    if (typeof a === 'string') {
        aDate = a && a !== '' ? parse(a, 'M/dd/yyyy', new Date()) : undefined;
    }
    if (typeof b === 'string') {
        bDate = b && b !== '' ? parse(b, 'M/dd/yyyy', new Date()) : undefined;
    }

    if (aDate === undefined) return 1; // Move undefined to the end
    if (bDate === undefined) return -1; // Move undefined to the end

    if (order === 'asc') {
        return isBefore(aDate, bDate) ? -1 : 1;
    } else {
        return isBefore(aDate, bDate) ? 1 : -1;
    }
};

export default sortPossibleUndefinedDates;
