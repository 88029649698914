import { FC, PropsWithChildren, useMemo, useRef, useState, KeyboardEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FiltersBox, RootBox, HeaderActionBox } from './Style';
import Dropdown, { IDropdownMenuItem } from '../../../../../../ui/general/dropdown/Dropdown';
import GraphCard from '../../../../../../pages/insights/components/graph/graph-card/GraphCard';
import {
    EGraphOption,
    EUserType,
    useInsightsOrganizationAssessmentDetailsStateValue
} from '../../../../../../contexts/InsightsOrganizationalAssessmentDetailsContext';
import {
    GraphCardToolbarFormControl,
    GraphCardToolbarFormControlLabel,
    GraphCardToolbarRadio,
    GraphCardToolbarRadioGroup,
    GraphCardToolbarValueBox
} from '../../../../Style';
import CheckboxUncheckedIcon from '../../../../../../assets/icons/CheckboxUncheckedIcon';
import CheckboxCheckedIcon from '../../../../../../assets/icons/CheckboxCheckedIcon';
import { IScoreLine } from '../../../../../../interfaces/IScoreLine';
import { mapInsightsGraphScoreDates } from '../../../../../../utils/insightsGraphDataSelector';
import { useInsightsOrganizationStateValue } from '../../../../../../contexts/InsightsOrganizationContext';
import { useCrumbsStateValue } from '../../../../../../contexts/CrumbsContext';
import { routes } from '../../../../../routes';

const OrganizationalAssessmentDetails: FC<PropsWithChildren> = () => {
    const [departmentsFilterDropdownItems, setDepartmentsFilterDropdownItems] = useState<IDropdownMenuItem[]>([]);
    const usersFilterDropdownItemsRef = useRef<IDropdownMenuItem[]>([
        {
            name: 'All Users',
            value: EUserType.ALL
        },
        {
            name: 'By Nulia Works License',
            value: EUserType.BY_LICENSE
        }
    ]);
    const { t } = useTranslation();
    const {
        scoreLines,
        departments,
        changeDepartmentFilterDropdownValue,
        selectedDepartmentFilterValue,
        selectedUserFilterValue,
        handleDepartmentFilterDropdownChange,
        handleUserFilterDropdownChange,
        skillName,
        selectedGraphOption,
        setSelectedGraphOption,
        graphBenchmark,
        isAssessmentDataLoading
    } = useInsightsOrganizationAssessmentDetailsStateValue();
    const { selectedTimespanOption, handleSelectedTimespanOptionChange } = useInsightsOrganizationStateValue();
    const graphOptionsRef = useRef<EGraphOption[]>([EGraphOption.SCORE, EGraphOption.ADOPTION]);
    const params = useParams();
    const { replaceLatestCrumb } = useCrumbsStateValue();
    const navigate = useNavigate();

    useEffect(() => {
        if (departments) {
            setDepartmentsFilterDropdownItems(
                departments?.map((department) => {
                    return {
                        name: department,
                        value: department
                    };
                })
            );
            if (departments.length > 0) changeDepartmentFilterDropdownValue(departments[0]);
        }
    }, [departments]);

    useEffect(() => {
        const hashFragment = location.hash;
        if (hashFragment && hashFragment !== '') {
            const preparedHashFragment = hashFragment.replace('#', '');
            setSelectedGraphOption(
                (preparedHashFragment.charAt(0).toUpperCase() + preparedHashFragment.slice(1)) as EGraphOption
            );
        }
    }, [location]);

    useEffect(() => {
        if (selectedGraphOption)
            replaceLatestCrumb({
                name: selectedGraphOption === EGraphOption.ADOPTION ? 'Assessment Adoption' : 'Assessment Score',
                pathname: `${routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT_DETAILS}#${selectedGraphOption.toString().toLowerCase()}`
            });
        navigate(`${location.pathname}#${selectedGraphOption.toLowerCase()}`, { replace: true });
    }, [selectedGraphOption]);

    const filterScoreLinesByType = (scoreLine: IScoreLine) => {
        return scoreLine.type === selectedGraphOption && scoreLine.subtype === selectedUserFilterValue;
    };

    const scoreLinesValuesMemo = useMemo(() => {
        if (isAssessmentDataLoading || !scoreLines) return undefined;
        return scoreLines.filter(filterScoreLinesByType);
    }, [scoreLines, isAssessmentDataLoading, selectedGraphOption]);

    const xAxisMemo = useMemo(() => {
        if (isAssessmentDataLoading || !scoreLines) return undefined;
        if (scoreLines?.length > 0) {
            return mapInsightsGraphScoreDates(scoreLines.filter(filterScoreLinesByType)[0]?.scores);
        }
        return [];
    }, [scoreLines, selectedTimespanOption, isAssessmentDataLoading, selectedGraphOption]);

    const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setSelectedGraphOption(value as EGraphOption);
    };

    const onRadioOptionKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === 'Enter') {
            handleRadioButtonChange(e as unknown as React.ChangeEvent<HTMLInputElement>);
        }
    };

    const customGraphCardToolbar = useMemo(() => {
        return (
            <GraphCardToolbarFormControl>
                <GraphCardToolbarRadioGroup
                    id='tab-mode'
                    value={selectedGraphOption}
                    name={`controlled-radio-buttons-group`}
                >
                    {graphOptionsRef.current.map((value) => {
                        return (
                            <GraphCardToolbarValueBox key={value} variant='body2'>
                                <GraphCardToolbarFormControlLabel
                                    value={value}
                                    control={
                                        <GraphCardToolbarRadio
                                            onChange={handleRadioButtonChange}
                                            icon={<CheckboxUncheckedIcon />}
                                            checkedIcon={<CheckboxCheckedIcon />}
                                            onKeyDown={onRadioOptionKeyDown}
                                            tabIndex={0}
                                            checked={selectedGraphOption === value}
                                        />
                                    }
                                    label={value}
                                    color='primary'
                                />
                            </GraphCardToolbarValueBox>
                        );
                    })}
                </GraphCardToolbarRadioGroup>
            </GraphCardToolbarFormControl>
        );
    }, [selectedGraphOption]);

    return (
        <RootBox key={params?.id}>
            <HeaderActionBox>
                <FiltersBox>
                    <Dropdown
                        items={
                            skillName
                                ? [
                                      {
                                          name: skillName,
                                          value: skillName
                                      }
                                  ]
                                : []
                        }
                        value={skillName || ''}
                        handleChange={() => {}}
                        style={{
                            width: '280px'
                        }}
                        defaultValue={skillName}
                        disabled
                    />
                    <Dropdown
                        items={departmentsFilterDropdownItems}
                        value={selectedDepartmentFilterValue}
                        handleChange={handleDepartmentFilterDropdownChange}
                        disabled={!selectedDepartmentFilterValue}
                        id='department-dropdown'
                    />
                    <Dropdown
                        items={usersFilterDropdownItemsRef.current}
                        value={selectedUserFilterValue}
                        handleChange={handleUserFilterDropdownChange}
                        disabled={!selectedDepartmentFilterValue}
                        id='user-dropdown'
                    />
                </FiltersBox>
            </HeaderActionBox>
            <div>
                <GraphCard
                    customToolbar={customGraphCardToolbar}
                    scoreLines={scoreLinesValuesMemo}
                    xAxis={xAxisMemo}
                    handleTimelineChange={handleSelectedTimespanOptionChange}
                    translations={{
                        graphCardTitle: t('insights.organization.assessment.graphCardTitle'),
                        graphCardHeaderTitle: skillName
                    }}
                    benchmarkStartValue={
                        selectedGraphOption === EGraphOption.SCORE
                            ? graphBenchmark?.scoreTargetLow
                            : graphBenchmark?.adoptionTarget
                    }
                    benchmarkEndValue={
                        selectedGraphOption === EGraphOption.SCORE ? graphBenchmark?.scoreTargetHigh : undefined
                    }
                    graphMaxValue={selectedGraphOption === EGraphOption.SCORE ? 135 : 100}
                    isDataEmpty={scoreLinesValuesMemo && scoreLinesValuesMemo.length === 0}
                    isLoading={isAssessmentDataLoading || scoreLinesValuesMemo === undefined}
                    selectedTimespanOption={selectedTimespanOption}
                />
            </div>
        </RootBox>
    );
};

export default OrganizationalAssessmentDetails;
