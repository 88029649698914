import { ChangeEvent, FC, useMemo } from 'react';
import { useTheme } from '@mui/system';
import { DisplayNameTypography, StatusCircle, StatusCircleWrapper } from './Style';
import GraphCard from '../../../../components/graph/graph-card/GraphCard';
import Table from '../../../../../../ui/general/table/Table';
import { ITableHeadCell } from '../../../../../../interfaces/ITableHeaderCell';
import {
    StyledSearchInput,
    SearchInputWrapper as MainSearchInputWrapper
} from '../../../../../../pages/insights/Style';
import { useInsightsOrganizationalSkillStateValue } from '../../../../../../contexts/InsightsOrganizationalSkillContext';
import { RootBox } from '../Style';
import { IInsightsOrganizationSkillVM } from '../../../../../../interfaces/IInsightsOrganizationSkillVM';
import { useInsightsStateValue } from '../../../../../../contexts/InsightsContext';
import { mapInsightsGraphScoreDates } from '../../../../../../utils/insightsGraphDataSelector';
import { useInsightsOrganizationStateValue } from '../../../../../../contexts/InsightsOrganizationContext';

interface ITableHeader {
    image: string;
    userName: string;
    department: string;
    region: string;
    userAchieved: boolean;
    producerAchieved: boolean;
    masterAchieved: boolean;
    skillScore: number;
}

const OrganizationalSkillDetailsTab: FC = () => {
    const { scoreLines, searchText, setSearchText, isSkillDataLoading, latestDateData } =
        useInsightsOrganizationalSkillStateValue();
    const { handleSelectedTimespanOptionChange, selectedTimespanOption } = useInsightsOrganizationStateValue();
    const { selectedSkillName } = useInsightsStateValue();
    const theme = useTheme();

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'image',
            label: '',
            disableSort: true
        },
        {
            id: 'userName',
            label: 'Display Name',
            minWidth: '130px',
            customRender: (value: string) => {
                return <DisplayNameTypography variant='subtitle2'>{value}</DisplayNameTypography>;
            }
        },
        {
            id: 'department',
            label: 'Department'
        },
        {
            id: 'region',
            label: 'Region',
            align: 'center'
        },
        {
            id: 'userAchieved',
            label: 'User Achieved',
            disablePadding: true,
            align: 'center',
            customRender: (value: number) => {
                if (!value) return <></>;
                return (
                    <StatusCircleWrapper>
                        <StatusCircle color={theme.palette.levels.master} />
                    </StatusCircleWrapper>
                );
            }
        },
        {
            id: 'producerAchieved',
            label: 'Producer Achieved',
            disablePadding: true,
            align: 'center',
            customRender: (value: number) => {
                if (!value) return <></>;
                return (
                    <StatusCircleWrapper>
                        <StatusCircle color={theme.palette.status.attained} />
                    </StatusCircleWrapper>
                );
            }
        },
        {
            id: 'masterAchieved',
            label: 'Master Achieved',
            disablePadding: true,
            align: 'center',
            customRender: (value: number) => {
                if (!value) return <></>;
                return (
                    <StatusCircleWrapper>
                        <StatusCircle color={theme.palette.status.attained} />
                    </StatusCircleWrapper>
                );
            }
        },
        {
            id: 'skillScore',
            label: 'Score'
        }
    ];

    const scoreLinesValuesMemo = useMemo(() => {
        if (isSkillDataLoading || !scoreLines) return undefined;
        return scoreLines;
    }, [scoreLines, isSkillDataLoading]);

    const xAxisMemo = useMemo(() => {
        if (isSkillDataLoading || !scoreLines) return undefined;
        if (scoreLines?.length > 0) {
            return mapInsightsGraphScoreDates(scoreLines[0].scores);
        }
        return [];
    }, [scoreLines, selectedTimespanOption, isSkillDataLoading]);

    return (
        <RootBox>
            <GraphCard
                scoreLines={scoreLinesValuesMemo}
                xAxis={xAxisMemo}
                handleTimelineChange={handleSelectedTimespanOptionChange}
                translations={{ graphCardTitle: selectedSkillName }}
                isDataEmpty={scoreLinesValuesMemo && scoreLinesValuesMemo.length === 0}
                isLoading={isSkillDataLoading || scoreLinesValuesMemo === undefined}
                selectedTimespanOption={selectedTimespanOption}
            />
            <MainSearchInputWrapper className='search-box'>
                <StyledSearchInput
                    placeholder='Search Users'
                    value={searchText || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (setSearchText) setSearchText(e.target.value);
                    }}
                />
            </MainSearchInputWrapper>
            <Table<IInsightsOrganizationSkillVM, ITableHeader>
                headCells={headCells}
                data={latestDateData}
                propertyKeys={headCells.map((headCell) => {
                    return headCell.id;
                })}
                tableTitlePlural=''
                isFilterControlVisible={false}
                isFilterDrawerOpen={false}
                isLoading={isSkillDataLoading}
                isImageColumnPresent
                imageColumnName='userNuliaGuid'
                initialOrderBy='userName'
                userImageInfoColumn='userName'
            />
        </RootBox>
    );
};

export default OrganizationalSkillDetailsTab;
