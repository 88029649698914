import { IAuthParams } from '../interfaces/IAuthParams';
import useAuthParams from '../hooks/useAuthParams';
import { apiInstance } from './helpers/apiInstance';
import { getTokenAndHeaders } from './helpers/apiUtil';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { mapAchievements, mapAchievementsOverview } from './helpers/mappers';
import { IAchievementGroupVM } from '../interfaces/views/IAchievementGroupVM';
import { IAchievementOverviewGroupVM } from '../interfaces/views/IAchievementOverviewGroupVM';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';

// GET ACHIEVEMENTS BY GROUP ID
export const getAchievementsByGroupIdQuery = async (authParams: IAuthParams, achievementGroupId?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`user/achievements/${achievementGroupId}`, headers);
    return mapAchievements(data);
};

export const useGetAchievementsByGroupIdQuery: (
    achievementGroupId?: string
) => UseQueryResult<IAchievementGroupVM, Error> = (achievementGroupId) => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ACHIEVEMENTS_FETCH_BY_GROUP_ID, achievementGroupId],
        queryFn: async () => {
            return getAchievementsByGroupIdQuery(authParams, achievementGroupId);
        },
        refetchOnWindowFocus: false,
        enabled: false
    });
};

// GET ACHIEVEMENTS
export const getAchievementsQuery = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`user/achievements`, headers);
    return mapAchievementsOverview(data);
};

export const useGetAchievementsQuery: () => UseQueryResult<IAchievementOverviewGroupVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ACHIEVEMENTS_FETCH_ALL],
        queryFn: async () => {
            return getAchievementsQuery(authParams);
        },
        refetchOnWindowFocus: false,
        enabled: true
    });
};
