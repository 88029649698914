import { routes } from '../pages/routes';

const pageNameAndUrlArray: { pageName: string; pageUrl: string }[] = [
    {
        pageName: 'Achievements Details',
        pageUrl: routes.ACHIEVEMENT_DETAIL
    },
    {
        pageName: 'Achievements Preview',
        pageUrl: routes.ACHIEVEMENTS_ALL_BY_STATE
    },
    {
        pageName: 'Admin',
        pageUrl: routes.ADMIN
    },
    {
        pageName: 'Admin History',
        pageUrl: routes.ADMIN_HISTORY
    },
    {
        pageName: 'Admin History Details',
        pageUrl: routes.ADMIN_HISTORY_DETAILS
    },
    {
        pageName: 'Admin Licenses',
        pageUrl: routes.ADMIN_LICENSES
    },
    {
        pageName: 'Admin Outcomes',
        pageUrl: routes.ADMIN_OUTCOMES
    },
    {
        pageName: 'Admin Roles',
        pageUrl: routes.ADMIN_ROLES
    },
    {
        pageName: 'All Badges',
        pageUrl: routes.BADGES_ALL_BY_CATEGORY
    },
    {
        pageName: 'Feedback',
        pageUrl: routes.FEEDBACK
    },
    {
        pageName: 'Home',
        pageUrl: routes.HOME
    },
    {
        pageName: 'How To',
        pageUrl: routes.HOW_TO
    },
    {
        pageName: "How To's Homepage",
        pageUrl: routes.HOW_TOS_HOMEPAGE
    },
    {
        pageName: "How To's playlist",
        pageUrl: routes.HOW_TO_PLAYLIST
    },
    {
        pageName: 'Insights',
        pageUrl: routes.INSIGHTS
    },
    {
        pageName: 'Insights Organization',
        pageUrl: routes.INSIGHTS_ORGANIZATIONAL
    },
    {
        pageName: 'Insights Organization Outcomes Details',
        pageUrl: routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS
    },
    {
        pageName: 'Insights Organization Assessment',
        pageUrl: routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT
    },
    {
        pageName: 'Insights Organization Assessment Details',
        pageUrl: routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT_DETAILS
    },
    {
        pageName: 'Insights Organization Badges',
        pageUrl: routes.INSIGHTS_ORGANIZATIONAL_BADGES
    },
    {
        pageName: 'Insights Organization Engagement',
        pageUrl: routes.INSIGHTS_ORGANIZATIONAL_ENGAGEMENT
    },
    {
        pageName: 'Insights Organization Skill details',
        pageUrl: routes.INSIGHTS_ORGANIZATIONAL_SKILL_DETAILS
    },
    {
        pageName: 'Insights Organization Outcomes',
        pageUrl: routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES
    },
    {
        pageName: 'Insights Organization Single Badge',
        pageUrl: routes.INSIGHTS_ORGANIZATIONAL_SINGLE_BADGE
    },
    {
        pageName: 'Insights Organization Users',
        pageUrl: routes.INSIGHTS_ORGANIZATIONAL_USERS
    },
    {
        pageName: 'Insights Organization User Badges',
        pageUrl: routes.INSIGHTS_ORGANIZATIONAL_USER_BADGES
    },
    {
        pageName: 'Insights Organization User Engagement',
        pageUrl: routes.INSIGHTS_ORGANIZATIONAL_USER_ENGAGEMENT
    },
    {
        pageName: 'Insights Organization User Engagement',
        pageUrl: routes.INSIGHTS_ORGANIZATIONAL_USER_ENGAGEMENT
    },
    {
        pageName: 'Insights Organization User Outcomes',
        pageUrl: routes.INSIGHTS_ORGANIZATIONAL_USER_OUTCOMES
    },
    {
        pageName: 'Insights Organization User Skills',
        pageUrl: routes.INSIGHTS_ORGANIZATIONAL_USER_SKILLS
    },
    {
        pageName: 'Insights Personal',
        pageUrl: routes.INSIGHTS_PERSONAL
    },
    {
        pageName: 'Insights Personal Badges',
        pageUrl: routes.INSIGHTS_PERSONAL_BADGES
    },
    {
        pageName: 'Insights Personal Engagement',
        pageUrl: routes.INSIGHTS_PERSONAL_ENGAGEMENT
    },
    {
        pageName: 'Insights Personal Outcomes',
        pageUrl: routes.INSIGHTS_PERSONAL_OUTCOMES
    },
    {
        pageName: 'Insights Personal Skills',
        pageUrl: routes.INSIGHTS_PERSONAL_SKILLS
    },
    {
        pageName: 'Instructions',
        pageUrl: routes.INSTRUCTIONS
    },
    {
        pageName: 'Linkedin',
        pageUrl: routes.LINKEDIN
    },
    {
        pageName: 'Outcome Details',
        pageUrl: routes.OUTCOME
    },
    {
        pageName: 'Outcomes',
        pageUrl: routes.OUTCOMES
    },
    {
        pageName: 'Outcomes History',
        pageUrl: routes.HISTORY_OUTCOMES
    },
    {
        pageName: 'Settings',
        pageUrl: routes.SETTINGS
    },
    {
        pageName: 'Settings Organization',
        pageUrl: routes.SETTINGS_ORGANIZATIONAL
    },
    {
        pageName: 'Settings Personal',
        pageUrl: routes.SETTINGS_PERSONAL
    },
    {
        pageName: 'Showcase Home',
        pageUrl: routes.SHOWCASE_HOME
    },
    {
        pageName: 'Skill Details',
        pageUrl: routes.SKILL
    },
    {
        pageName: 'Skills',
        pageUrl: routes.SKILLS
    },
    {
        pageName: 'Something Went Wrong',
        pageUrl: routes.SOMETHING_WENT_WRONG
    },
    {
        pageName: 'Forbidden',
        pageUrl: routes.PAGE_FORBIDDEN
    },
    {
        pageName: 'Outcomes',
        pageUrl: routes.USER_OUTCOMES
    },
    {
        pageName: 'Outcomes',
        pageUrl: routes.TAB_OUTCOMES
    },
    {
        pageName: 'Login',
        pageUrl: routes.LOGIN
    }
];

export const getPageNameByUrl: (pageUrl: string) => string | undefined = (pageUrl) => {
    return pageNameAndUrlArray.find((item) => item.pageUrl === pageUrl)?.pageName;
};
