import { progressDoughnutChartType } from '../../utils/progressDoughnutChartType';
import { IUserSkillDTO } from '../../interfaces/dtos/IUserSkillDTO';
import { IUserSkillVM } from '../../interfaces/views/IUserSkillVM';
import { ESkillLevel } from '../../interfaces/enums/ESkillLevel';
import { ISingleUserSkillDTO } from '../../interfaces/dtos/ISingleUserSkillDTO';
import { ISingleUserSkillVM } from '../../interfaces/views/ISingleUserSkillVM';
import { ESkillType } from '../../interfaces/enums/ESkillType';
import { IAllOutcomeDTO } from '../../interfaces/dtos/IAllOutcomeDTO';
import { IUserOutcomeVM } from '../../interfaces/views/IUserOutcomeVM';
import { OutcomeDTO } from '../../interfaces/dtos/OutcomeDTO';
import { ISingleUserOutcomeVM } from '../../interfaces/views/ISingleUserOutcomeVM';
import { ECompetenceState } from '../../interfaces/enums/ECompetenceState';
import { ICurrentUserDTO } from '../../interfaces/dtos/ICurrentUserDTO';
import { ICurrentUserVM } from '../../interfaces/views/ICurrentUserVM';
import { IAdUserDTO } from '../../interfaces/dtos/IAdUserDTO';
import { IAdUserVM } from '../../interfaces/views/IAdUserVM';
import { parse } from 'date-fns';
import { IMotwDTO } from '../../interfaces/dtos/IMotwDTO';
import { IMotwVM } from '../../interfaces/views/IMotwVM';
import { parseISO } from 'date-fns';
import { ENuliaLicenseType } from '../../interfaces/enums/ENuliaLicenseType';
import { IRecommendationDTO } from '../../interfaces/dtos/IRecommendationDTO';
import { IRecommendationVM } from '../../interfaces/views/IRecommendationVM';
import { IActivityFeedVM } from '../../interfaces/views/IActivityFeedVM';
import { IActivityFeedDTO } from '../../interfaces/dtos/IActivityFeedDTO';
import { EActivityFeedCategory } from '../../interfaces/enums/EActivityFeedCategory';
import { IAdminOutcomeDTO } from '../../interfaces/dtos/IAdminOutcomeDTO';
import { IAdminOutcomeVM } from '../../interfaces/views/IAdminOutcomeVM';
import { IRoleVM } from '../../interfaces/views/IRoleVM';
import { convertRolesToEnumValues } from '../../utils/convertRolesToEnumValues';
import { ITenantLicensedUserVM } from '../../interfaces/views/ITenantLicensedUserVM';
import { ITenantHistoryDTO } from '../../interfaces/dtos/ITenantHistoryDTO';
import { ITenantHistoryVM } from '../../interfaces/views/ITenantHistoryVM';
import { ETenantHistoryStatus } from '../../interfaces/enums/ETenantHistoryStatus';
import { ETenantHistoryType } from '../../interfaces/enums/ETenantHistoryType';
import { ETenantHistoryTargetSetting } from '../../interfaces/enums/ETenantHistoryTargetSetting';
import { ITenantJobTaskStatusVM } from '../../interfaces/views/ITenantJobTaskStatusVM';
import { ITenantJobTaskStatusDTO } from '../../interfaces/dtos/ITenantJobTaskStatusDTO';
import { IInsightsPersonalVM } from '../../interfaces/views/IInsightsPersonalVM';
import { IInsightsOrganizationUsersCompetenceVM } from '../../interfaces/views/IInsightsOrganizationUsersCompetenceVM';
import { IInsightsOrganizationOutcomeVM } from '../../interfaces/views/IInsightsOrganizationOutcomeVM';
import { IInsightsOrganizationBadgesVM } from '../../interfaces/views/IInsightsOrganizationBadgesVM';
import { IInsightsOrganizationEngagementVM } from '../../interfaces/views/IInsightsOrganizationEngagementVM';
import { IInsightsPersonalSkillDTO } from '../../interfaces/dtos/IInsightsPersonalSkillDTO';
import { IInsightsPersonalSkillVM } from '../../interfaces/views/IInsightsPersonalSkillVM';
import { IInsightsOrganizationOutcomesUserVM } from '../../interfaces/views/IInsightsOganizationOutcomesUserVM';
import { IInsightsOrganizationOutcomesSkillVM } from '../../interfaces/views/IInsightsOrganizationOutcomesSkillVM';
import { IInsightsPersonalBadgeVM } from '../../interfaces/views/IInsightsPersonalBadgeVM';
import { IInsightsPersonalOutcomeVM } from '../../interfaces/views/IInsightsPersonalOutcomeVM';
import { IInsightsPersonalOutcomeDTO } from '../../interfaces/dtos/IInsightsPersonalOutcomeDTO';
import { IInsightsPersonalBadgeDTO } from '../../interfaces/dtos/IInsightsPersonalBadgeDTO';
import { EBadgeLevel } from '../../interfaces/enums/EBadgeLevel';
import { EInsightsOutcomeLevel } from '../../interfaces/enums/EInsightsOutcomeLevel';
import { convertLogicalRolesToEnumValues } from '../../utils/convertLogicalRolesToEnumValues';
import { convertPermissionsToEnumValues } from '../../utils/convertPermissionsToEnumValues';
import { EUserRoleString } from '../../interfaces/enums/EUserRoleString';
import { EUserRoleLogical } from '../../interfaces/enums/EUserRoleLogical';
import { IInsightsOrganizationalUserBadgeVM } from '../../interfaces/views/IInsightsOrganizationalUserBadgeVM';
import { IInsightsOrganizationalUserBadgeDTO } from '../../interfaces/dtos/IInsightsOrganizationalUserBadgeDTO';
import { IInsightsOrganizationalUserOutcomeDTO } from '../../interfaces/dtos/IInsightsOrganizationalUserOutcomeDTO';
import { IInsightsOrganizationalUserSkillDTO } from '../../interfaces/dtos/IInsightsOrganizationalUserSkillDTO';
import { IInsightsOrganizationalUserSkillVM } from '../../interfaces/views/IInsightsOrganizationalUserSkillVM';
import { IInsightsOrganizationalUserOutcomeVM } from '../../interfaces/views/IInsightsOrganizationalUserOutcomeVM';
import { IInsightsPersonalEngagementPointDTO } from '../../interfaces/dtos/IInsightsPersonalEngagementPointDTO';
import { IInsightsPersonalEngagementPointVM } from '../../interfaces/views/IInsightsPersonalEngagementPointVM';
import { ILoggedInDTO } from '../../interfaces/dtos/ILoggedInDTO';
import { ILoggedInVM } from '../../interfaces/views/ILoggedInVM';
import { IInsightsOrganizationSkillVM } from '../../interfaces/IInsightsOrganizationSkillVM';
import { IAssessmentReportItemVM } from '../../interfaces/views/IAssessmentReportItemVM';
import * as d3 from 'd3';
import { IOrganizationalBadgeVM } from '../../interfaces/views/IOrganizationalBadgeVM';
import { IOrganizationalUserForBadgeVM } from '../../interfaces/views/IOrganizationalUserForBadgeVM';
import { IOrganizationalBadgeDTO } from '../../interfaces/dtos/IOrganizationalBadgeDTO';
import { IHowToCategoryVM } from '../../interfaces/views/IHowToCategoryVM';
import { IHowToCategoryDTO } from '../../interfaces/dtos/IHowToCategoryDTO';
import { IHowToPlaylistDTO } from '../../interfaces/dtos/IHowToPlaylistDTO';
import { IHowToPlaylistVM } from '../../interfaces/views/IHowToPlaylistVM';
import { EHowToPlaylistType } from '../../interfaces/enums/EHowToPlaylistType';
import { IAchievementOverviewGroupVM } from '../../interfaces/views/IAchievementOverviewGroupVM';
import { ERecommendationCompetenceLevel } from '../../interfaces/enums/ERecommendationCompetenceLevel';
import { IGetShowcaseBadgeResponseDTO } from '../../interfaces/dtos/IGetShowcaseBadgeResponseDTO';
import { IGetShowcaseBadgeResponseVM } from '../../interfaces/views/IGetShowcaseBadgeResponseVM';
import { IShowcaseBadgeDTO } from '../../interfaces/dtos/IShowcaseBadgeDTO';
import { EAchievementState } from '../../interfaces/enums/EAchievementState';
import { IAchievementGroupVM } from '../../interfaces/views/IAchievementGroupVM';
import { IAchievementGroupDTO } from '../../interfaces/dtos/IAchievementGroupDTO';
import { EAchievementCategory } from '../../interfaces/enums/EAchievementCategory';
import { IAchievementsOverviewResponseDTO } from '../../interfaces/dtos/IAchievementsOverviewResponseDTO';
import { IAchievementOverviewDTO } from '../../interfaces/dtos/IAchievementOverviewDTO';
import { EShowcaseAchievementCategory } from '../../interfaces/enums/EShowcaseAchievementCategory';
import { IUserRankingsResponseDTO } from '../../interfaces/dtos/IUserRankingsResponseDTO';
import { IUserRankingsVM } from '../../interfaces/views/IUserRankingsVM';
import { IUserRankingDTO } from '../../interfaces/dtos/IUserRankingDTO';
import { IUserRankingVM } from '../../interfaces/views/IUserRankingVM';
import { EUserRanking } from '../../interfaces/enums/EUserRanking';
import { ERankingMedal } from '../../interfaces/enums/ERankingMedal';
import { EShowcaseBadgeLevel } from '../../interfaces/enums/EShowcaseBadgeLevel';
import { convertTenantPreferencesToEnumValues } from '../../utils/convertTenantPreferencesToEnumValues';
import { IHighlightVM } from '../../interfaces/views/IHighlightVM';
import { IHighlightDTO } from '../../interfaces/dtos/IHighlightDTO';
import { EHighlightType } from '../../interfaces/enums/EHighlightType';
import { ITenantStatusDTO } from '../../interfaces/dtos/ITenantStatusDTO';
import { ITenantStatusVM } from '../../interfaces/views/ITenantStatusVM';
import { ETenantStatusColor } from '../../interfaces/enums/ETenantStatusColor';
import { EAnonymizedReportStatus } from '../../interfaces/enums/EAnonymizedReportStatus';
import { IActivity } from '../../interfaces/IActivity';

export const mapUserSkills: (userSkillDTOList: IUserSkillDTO[]) => IUserSkillVM[] = (userSkillDTOList) => {
    return userSkillDTOList.map((userSkillDTO: IUserSkillDTO) => {
        return {
            ...userSkillDTO,
            progressDoughnutChartType: progressDoughnutChartType(userSkillDTO.assignment, userSkillDTO.skillType),
            isUserFavoriteControlDisabled: userSkillDTO.assignment.level === ESkillLevel.NONE,
            isAssigned:
                userSkillDTO.assignment.level === ESkillLevel.PRODUCER ||
                userSkillDTO.assignment.level === ESkillLevel.MASTER
        };
    });
};

export const mapSingleUserSkill: (singleUserSkillDTO: ISingleUserSkillDTO) => ISingleUserSkillVM = (
    singleUserSkillDTO
) => {
    return {
        ...singleUserSkillDTO,
        progressDoughnutChartType: progressDoughnutChartType(
            singleUserSkillDTO.assignment,
            singleUserSkillDTO.skillType
        ),
        isStandardType: singleUserSkillDTO.skillType === ESkillType.STANDARD,
        isBestPracticeType: singleUserSkillDTO.skillType === ESkillType.BEST_PRACTICE,
        isAssigned: singleUserSkillDTO.assignment.level !== ESkillLevel.NONE,
        isUserFavoriteControlDisabled: singleUserSkillDTO.assignment.level === ESkillLevel.NONE
    };
};

export const mapUserOutcomes: (userOutcomeDTOList: IAllOutcomeDTO[]) => IUserOutcomeVM[] = (userOutcomeDTOList) => {
    return userOutcomeDTOList.map((userOutcomeDTO: IAllOutcomeDTO) => {
        return {
            ...userOutcomeDTO,
            isUserFavoriteControlDisabled: userOutcomeDTO.assignment.level === ESkillLevel.NONE,
            isAssigned: userOutcomeDTO.assignment.level !== ESkillLevel.NONE,
            numberOfInProgressSkills: userOutcomeDTO.userSkillStates.filter((userSkillState) => {
                if (userSkillState.assignment.level === ESkillLevel.PRODUCER)
                    return userSkillState.assignment.producerState === ECompetenceState.IN_PROGRESS;
                if (userSkillState.assignment.level === ESkillLevel.MASTER)
                    return userSkillState.assignment.masterState === ECompetenceState.IN_PROGRESS;
                return false;
            }).length,
            numberOfAchievedSkills: userOutcomeDTO.userSkillStates.filter((userSkillState) => {
                if (userSkillState.assignment.level === ESkillLevel.PRODUCER)
                    return userSkillState.assignment.producerState === ECompetenceState.MAINTAIN;
                if (userSkillState.assignment.level === ESkillLevel.MASTER)
                    return userSkillState.assignment.masterState === ECompetenceState.MAINTAIN;
                return false;
            }).length,
            numberOfNeedAttentionSkills: userOutcomeDTO.userSkillStates.filter((userSkillState) => {
                return (
                    (userSkillState.assignment.level === ESkillLevel.MASTER &&
                        userSkillState.assignment.masterState === ECompetenceState.NEEDS_ATTENTITON) ||
                    (userSkillState.assignment.level === ESkillLevel.PRODUCER &&
                        userSkillState.assignment.producerState === ECompetenceState.NEEDS_ATTENTITON)
                );
            }).length
        };
    });
};

export const mapSingleUserOutcome: (singleUserOutcomeDTO: OutcomeDTO) => ISingleUserOutcomeVM = (
    singleUserOutcomeDTO
) => {
    return {
        ...singleUserOutcomeDTO,
        isMasterTargetControlDisabled: singleUserOutcomeDTO.assignment.level === ESkillLevel.NONE,
        numberOfInProgressSkills: singleUserOutcomeDTO.userSkills.filter(
            (userSkill) =>
                (userSkill.assignment.level === ESkillLevel.MASTER &&
                    userSkill.assignment.masterState === ECompetenceState.IN_PROGRESS) ||
                (userSkill.assignment.level === ESkillLevel.PRODUCER &&
                    userSkill.assignment.producerState === ECompetenceState.IN_PROGRESS)
        ).length,
        numberOfAchievedSkills: singleUserOutcomeDTO.userSkills.filter(
            (userSkill) =>
                (userSkill.assignment.level === ESkillLevel.MASTER &&
                    userSkill.assignment.masterState === ECompetenceState.MAINTAIN) ||
                (userSkill.assignment.level === ESkillLevel.PRODUCER &&
                    userSkill.assignment.producerState === ECompetenceState.MAINTAIN)
        ).length,
        numberOfNeedAttentionSkills: singleUserOutcomeDTO.userSkills.filter((userSkill) => {
            return (
                (userSkill.assignment.level === ESkillLevel.MASTER &&
                    userSkill.assignment.masterState === ECompetenceState.NEEDS_ATTENTITON) ||
                (userSkill.assignment.level === ESkillLevel.PRODUCER &&
                    userSkill.assignment.producerState === ECompetenceState.NEEDS_ATTENTITON)
            );
        }).length
    };
};

export const mapCurrentUser: (currentUserDTO: ICurrentUserDTO) => ICurrentUserVM = (currentUserDTO) => {
    return {
        ...currentUserDTO,
        nextScoredDate: currentUserDTO?.nextScoredDate
            ? parse(currentUserDTO.nextScoredDate, 'yyyy-MM-dd', new Date())
            : undefined,
        lastScoredDate: currentUserDTO.lastScoredDate
            ? parse(currentUserDTO.lastScoredDate, 'yyyy-MM-dd', new Date())
            : undefined,
        roles: convertRolesToEnumValues(currentUserDTO.roles),
        permissions: convertPermissionsToEnumValues(currentUserDTO.permissions),
        tenantPreferences: convertTenantPreferencesToEnumValues(currentUserDTO.tenantPreferences),
        welcomeVideo: currentUserDTO.welcomeVideo as IActivity
    };
};

export const mapTenantUsersToRoles: (tenantUsers: ITenantLicensedUserVM[]) => IRoleVM[] = (tenantUsers) => {
    return tenantUsers.map((tenantUser) => {
        return {
            id: tenantUser.id,
            country: tenantUser.country,
            department: tenantUser.department,
            name: tenantUser.name,
            roles: convertLogicalRolesToEnumValues(tenantUser.nwRoles)
        };
    });
};

export const mapAdUsers: (adUserDTOList: IAdUserDTO[]) => IAdUserVM[] = (adUserDTOList) => {
    return adUserDTOList.map((adUserDTO) => {
        return {
            ...adUserDTO,
            adLicenseType: adUserDTO.adLicenseType,
            nuliaLicenseType: adUserDTO.nuliaLicenseType as ENuliaLicenseType
        };
    });
};

export const mapMOTWs: (motwDTOList: IMotwDTO[]) => IMotwVM[] = (motwDTOList) => {
    return motwDTOList.map((motwDTO) => {
        return {
            ...motwDTO,
            actionName: motwDTO.actionName && motwDTO.actionName !== '' ? motwDTO.actionName : undefined,
            actionUrl: motwDTO.actionUrl && motwDTO.actionUrl !== '' ? motwDTO.actionUrl : undefined
        };
    });
};

export const mapRecommendations: (recommendations: IRecommendationDTO[]) => IRecommendationVM[] = (recommendations) => {
    const competenceMapperMap = new Map<number, ESkillLevel>();
    competenceMapperMap.set(0, ESkillLevel.NONE);
    competenceMapperMap.set(2, ESkillLevel.PRODUCER);
    competenceMapperMap.set(3, ESkillLevel.MASTER);
    const subcompetenceStateMapperMap = new Map<string, ERecommendationCompetenceLevel>();
    subcompetenceStateMapperMap.set('None', ERecommendationCompetenceLevel.NONE);
    subcompetenceStateMapperMap.set('InProgress', ERecommendationCompetenceLevel.IN_PROGRESS);
    subcompetenceStateMapperMap.set('Maintain', ERecommendationCompetenceLevel.MAINTAIN);
    subcompetenceStateMapperMap.set('NeedsAttention', ERecommendationCompetenceLevel.NEEDS_ATTENTION);
    return recommendations.map((recommendation) => {
        return {
            ...recommendation,
            competenceState: subcompetenceStateMapperMap.get(
                recommendation.competenceState
            ) as ERecommendationCompetenceLevel,
            competenceAssignmentLevel:
                (competenceMapperMap.get(recommendation.competenceAssignmentLevel) as ESkillLevel) || ESkillLevel.NONE,
            subcompetencesState: recommendation.subcompetencesState?.map((subcompetence) => {
                return subcompetenceStateMapperMap.get(subcompetence) as ERecommendationCompetenceLevel;
            })
        };
    });
};

export const mapActivityFeedData: (data: IActivityFeedDTO[]) => IActivityFeedVM[] = (data) => {
    return data.map((activityFeedItem) => {
        return {
            ...activityFeedItem,
            category: activityFeedItem.category as EActivityFeedCategory,
            date: parseISO(activityFeedItem.date),
            icon: activityFeedItem.icon && activityFeedItem.icon !== '' ? activityFeedItem.icon : undefined
        };
    });
};

export const mapHighlightsData: (data: IHighlightDTO[]) => IHighlightVM[] = (data) => {
    return data.map((highlightItem) => {
        return {
            ...highlightItem,
            type: highlightItem.type as EHighlightType
        };
    });
};

export const mapAdminOutcomes: (adminOutcomeDTOList: IAdminOutcomeDTO[]) => IAdminOutcomeVM[] = (
    adminOutcomeDTOList
) => {
    return adminOutcomeDTOList.map((adminOutcomeDTO: IAdminOutcomeDTO) => {
        return {
            ...adminOutcomeDTO
        };
    });
};

export const mapTenantHistoryData: (tenantHistoryList: ITenantHistoryDTO[]) => ITenantHistoryVM[] = (
    tenantHistoryList
) => {
    return tenantHistoryList.map((tenantHistoryItem) => {
        return {
            ...tenantHistoryItem,
            id: tenantHistoryItem.guid,
            status: tenantHistoryItem.status as ETenantHistoryStatus,
            type: tenantHistoryItem.type as ETenantHistoryType,
            date: parseISO(tenantHistoryItem.date),
            targets: [
                ...tenantHistoryItem.targets.map((tenantHistoryItemTarget) => {
                    let logicalRole: EUserRoleLogical | undefined;
                    if (tenantHistoryItemTarget.userRole) {
                        if (tenantHistoryItemTarget.userRole === EUserRoleString.ADMIN_REPORTS) {
                            logicalRole = EUserRoleLogical.ADMIN_REPORTS;
                        } else if (
                            tenantHistoryItemTarget.userRole === EUserRoleString.ADMIN_LICENSES ||
                            tenantHistoryItemTarget.userRole === 'AdminLicense'
                        ) {
                            logicalRole = EUserRoleLogical.ADMIN_LICENSES;
                        } else if (tenantHistoryItemTarget.userRole === EUserRoleString.ADMIN_GLOBAL) {
                            logicalRole = EUserRoleLogical.ADMIN_GLOBAL;
                        }
                    }
                    return {
                        ...tenantHistoryItemTarget,
                        setting: tenantHistoryItemTarget.setting as ETenantHistoryTargetSetting,
                        userRole: logicalRole ? [logicalRole] : []
                    };
                })
            ]
        };
    });
};

export const mapTenantJobTaskStatus: (
    tenantJobTaskStatusList: ITenantJobTaskStatusDTO[]
) => ITenantJobTaskStatusVM[] = (tenantJobTaskStatusList) => {
    return tenantJobTaskStatusList.map((tenantJobTaskStatusItem) => {
        return {
            ...tenantJobTaskStatusItem,
            status: tenantJobTaskStatusItem.status as ETenantHistoryStatus,
            id: tenantJobTaskStatusItem.platformId
        };
    });
};

export const mapInsightsPersonalData: (insightsPersonalData: string) => IInsightsPersonalVM[] = (
    insightsPersonalData
) => {
    const insightPersonalData: IInsightsPersonalVM[] = [];
    const parsedRows = d3.csvParseRows(insightsPersonalData);
    if (parsedRows.length < 1) return insightPersonalData;
    const columnNamesRow = parsedRows[0];
    if (columnNamesRow.length < 15) return insightPersonalData;
    parsedRows.slice(1).forEach((row) => {
        const date = parse(row[0].toString(), 'M/dd/yyyy', new Date());
        const item: IInsightsPersonalVM = {
            id: Math.random().toString(),
            date,
            skillsAssigned: parseInt(row[1]),
            skillsAttained: parseInt(row[2]),
            skillsNeedAttention: parseInt(row[3]),
            skillsInProgress: parseInt(row[4]),
            outcomesAssigned: parseInt(row[5]),
            outcomesAttained: parseInt(row[6]),
            outcomesNeedAttention: parseInt(row[7]),
            outcomesInProgress: parseInt(row[8]),
            logins: parseInt(row[9]),
            engagementPoints: parseInt(row[10]),
            badgesShared: parseInt(row[11]),
            badgesClaimed: parseInt(row[12]),
            badgesReady: parseInt(row[13]),
            badgesNeedAttention: parseInt(row[14]),
            month: date.getMonth()
        };
        insightPersonalData.push(item);
    });
    return insightPersonalData;
};

export const mapInsightsOrganizationUsersCompetenceData: (
    usersCompetenceData: string
) => IInsightsOrganizationUsersCompetenceVM[] = (usersCompetenceData) => {
    const insightOrganizationData: IInsightsOrganizationUsersCompetenceVM[] = [];
    const parsedRows = d3.csvParseRows(usersCompetenceData);
    if (parsedRows.length < 1) return insightOrganizationData;
    const columnNamesRow = parsedRows[0];
    if (columnNamesRow.length < 13) return insightOrganizationData;
    parsedRows.slice(1).forEach((row) => {
        const date = parse(row[0].toString(), 'M/dd/yyyy', new Date());
        const item: IInsightsOrganizationUsersCompetenceVM = {
            id: Math.random().toString(),
            date,
            userId: row[1],
            displayName: row[2],
            department: row[3],
            region: row[4],
            skillsAssigned: parseInt(row[5]),
            skillsInProgress: parseInt(row[6]),
            skillsAttained: parseInt(row[7]),
            skillsNeedAttention: parseInt(row[8]),
            outcomesAssigned: parseInt(row[9]),
            outcomesInProgress: parseInt(row[10]),
            outcomesAttained: parseInt(row[11]),
            outcomesNeedAttention: parseInt(row[12])
        };
        insightOrganizationData.push(item);
    });
    return insightOrganizationData;
};

export const mapInsightsOrganizationOutcomesData: (outcomesData: string) => IInsightsOrganizationOutcomeVM[] = (
    outcomesData
) => {
    const insightOrganizationData: IInsightsOrganizationOutcomeVM[] = [];
    const parsedRows = d3.csvParseRows(outcomesData);
    if (parsedRows.length < 1) return insightOrganizationData;
    const columnNamesRow = parsedRows[0];
    if (columnNamesRow.length < 10) return insightOrganizationData;
    parsedRows.slice(1).forEach((row) => {
        const date = parse(row[0].toString(), 'M/dd/yyyy', new Date());
        const item: IInsightsOrganizationOutcomeVM = {
            id: Math.random().toString(),
            date,
            outcomeId: parseInt(row[1]),
            outcomeName: row[2],
            outcomeImage: row[3],
            inProgress: parseInt(row[4]),
            assigned: parseInt(row[5]),
            attained: parseInt(row[6]),
            needAttention: parseInt(row[7]),
            userAchieved: parseInt(row[8]),
            important: row[9].toLowerCase() === 'true' ? true : false
        };
        insightOrganizationData.push(item);
    });
    return insightOrganizationData;
};

export const mapInsightsOrganizationEngagement: (outcomesData: string) => IInsightsOrganizationEngagementVM[] = (
    outcomesData
) => {
    const insightOrganizationData: IInsightsOrganizationEngagementVM[] = [];
    const parsedRows = d3.csvParseRows(outcomesData);
    if (parsedRows.length < 1) return insightOrganizationData;
    const columnNamesRow = parsedRows[0];
    if (columnNamesRow.length < 10) return insightOrganizationData;
    parsedRows.slice(1).forEach((row) => {
        const date = parse(row[0].toString(), 'M/dd/yyyy', new Date());
        const item: IInsightsOrganizationEngagementVM = {
            id: Math.random().toString(),
            date,
            userId: row[1],
            displayName: row[2],
            department: row[3],
            region: row[4],
            loginCount: parseInt(row[5]),
            lastLogin: row[6],
            pointsLastMonth: parseInt(row[7]),
            pointsThisMonth: parseInt(row[8]),
            pointsTotal: parseInt(row[9])
        };
        insightOrganizationData.push(item);
    });
    return insightOrganizationData;
};

export const mapInsightsOrganizationBadges: (badgesData: string) => IInsightsOrganizationBadgesVM[] = (badgesData) => {
    const insightOrganizationData: IInsightsOrganizationBadgesVM[] = [];
    const parsedRows = d3.csvParseRows(badgesData);
    if (parsedRows.length < 1) return insightOrganizationData;
    const columnNamesRow = parsedRows[0];
    if (columnNamesRow.length < 9) return insightOrganizationData;
    parsedRows.slice(1).forEach((row) => {
        const date = parse(row[0].toString(), 'M/dd/yyyy', new Date());
        const item: IInsightsOrganizationBadgesVM = {
            id: Math.random().toString(),
            date,
            userId: row[1],
            displayName: row[2],
            department: row[3],
            region: row[4],
            shared: parseInt(row[5]),
            claimed: parseInt(row[6]),
            readyToClaim: parseInt(row[7]),
            needAttention: parseInt(row[8])
        };
        insightOrganizationData.push(item);
    });
    return insightOrganizationData;
};

export const mapInsightsUserSkills: (userSkillsList: IInsightsPersonalSkillDTO[]) => IInsightsPersonalSkillVM[] = (
    userSkillsList
) => {
    return userSkillsList.map((item) => {
        return {
            ...item,
            id: item.skillId,
            assignmentDate: parseISO(item.assignmentDate),
            producerLevelState: item.producerLevelState as ECompetenceState,
            masterLevelState: item.masterLevelState as ECompetenceState,
            level: item.level as ESkillLevel,
            skillType: item.skillType as ESkillType
        };
    });
};

export const mapInsightsUserOutcomes: (
    userOutcomesList: IInsightsPersonalOutcomeDTO[]
) => IInsightsPersonalOutcomeVM[] = (userOutcomesList) => {
    return userOutcomesList.map((item) => {
        return {
            ...item,
            id: item.outcomeId,
            assignedDate: parseISO(item.assignedDate),
            level: item.level as EInsightsOutcomeLevel
        };
    });
};

export const mapInsightsUserBadges: (userBadgesList: IInsightsPersonalBadgeDTO[]) => IInsightsPersonalBadgeVM[] = (
    userBadgesList
) => {
    return userBadgesList.map((item) => {
        return {
            ...item,
            state: item.state as EBadgeLevel,
            claimedDate: item.claimedDate ? parseISO(item.claimedDate) : undefined,
            verifiedDate: item.verifiedDate ? parseISO(item.verifiedDate) : undefined,
            sharedDate: item.sharedDate ? parseISO(item.sharedDate) : undefined,
            level: item.level as EShowcaseBadgeLevel
        };
    });
};

export const mapInsightsOrganizationOutcomesUsers: (usersData: string) => IInsightsOrganizationOutcomesUserVM[] = (
    usersData
) => {
    const insightOrganizationData: IInsightsOrganizationOutcomesUserVM[] = [];
    const parsedRows = d3.csvParseRows(usersData);
    if (parsedRows.length < 1) return insightOrganizationData;
    const columnNamesRow = parsedRows[0];
    if (columnNamesRow.length < 13) return insightOrganizationData;
    parsedRows.slice(1).forEach((row) => {
        const date = parse(row[0].toString(), 'M/dd/yyyy', new Date());
        const item: IInsightsOrganizationOutcomesUserVM = {
            id: Math.random().toString(),
            date,
            userId: row[1],
            displayName: row[2],
            department: row[3],
            region: row[4],
            assigned: parseInt(row[5]),
            inProgress: parseInt(row[6]),
            attained: parseInt(row[7]),
            needAttention: parseInt(row[8]),
            userAchieved: parseInt(row[9]),
            skillsTotal: parseInt(row[10]),
            skillsAttained: parseInt(row[11]),
            skillsNotAttained: parseInt(row[12]),
            outcomeName: row[13]
        };
        insightOrganizationData.push(item);
    });
    return insightOrganizationData;
};

export const mapInsightsOrganizationOutcomesSkills: (skillsData: string) => IInsightsOrganizationOutcomesSkillVM[] = (
    skillsData
) => {
    const insightOrganizationData: IInsightsOrganizationOutcomesSkillVM[] = [];
    const parsedRows = d3.csvParseRows(skillsData);
    if (parsedRows.length < 1) return insightOrganizationData;
    const columnNamesRow = parsedRows[0];
    if (columnNamesRow.length < 8) return insightOrganizationData;
    parsedRows.slice(1).forEach((row) => {
        const date = parse(row[0].toString(), 'M/dd/yyyy', new Date());
        const item: IInsightsOrganizationOutcomesSkillVM = {
            id: row[1],
            date,
            skillId: parseInt(row[1]),
            skillName: row[2],
            inProgress: parseInt(row[3]),
            assigned: parseInt(row[4]),
            attained: parseInt(row[5]),
            notAttained: parseInt(row[6]),
            needAttention: parseInt(row[7]),
            outcomeName: row[8]
        };
        insightOrganizationData.push(item);
    });
    return insightOrganizationData;
};

export const mapInsightsOrganizationalUserBadgesData: (
    userBadgesList: IInsightsOrganizationalUserBadgeDTO[]
) => IInsightsOrganizationalUserBadgeVM[] = (userBadgesList) => {
    return userBadgesList.map((userBadgeItem) => {
        return {
            ...userBadgeItem,
            claimedDate: userBadgeItem.claimedDate ? parseISO(userBadgeItem.claimedDate) : undefined,
            verifiedDate: userBadgeItem.verifiedDate ? parseISO(userBadgeItem.verifiedDate) : undefined,
            level: userBadgeItem.level as EShowcaseBadgeLevel
        };
    });
};

export const mapInsightsOrganizationalUserOutcomesData: (
    userBadgesList: IInsightsOrganizationalUserOutcomeDTO[]
) => IInsightsOrganizationalUserOutcomeVM[] = (userOutcomesList) => {
    return userOutcomesList.map((userOutcomeItem) => {
        return {
            ...userOutcomeItem,
            id: userOutcomeItem.outcomeId,
            assignedDate: parseISO(userOutcomeItem.assignedDate),
            level: userOutcomeItem.level as EInsightsOutcomeLevel
        };
    });
};

export const mapInsightsOrganizationalUserSkillsData: (
    userBadgesList: IInsightsOrganizationalUserSkillDTO[]
) => IInsightsOrganizationalUserSkillVM[] = (userSkillsList) => {
    return userSkillsList.map((userSkillItem) => {
        return {
            ...userSkillItem,
            id: userSkillItem.skillId,
            assignmentDate: parseISO(userSkillItem.assignmentDate),
            producerLevelState: userSkillItem.producerLevelState as ECompetenceState,
            masterLevelState: userSkillItem.masterLevelState as ECompetenceState,
            level: userSkillItem.level as ESkillLevel,
            skillType: userSkillItem.skillType as ESkillType
        };
    });
};

export const mapInsightsPersonalEngagementPoints: (
    engagementPointList: IInsightsPersonalEngagementPointDTO[]
) => IInsightsPersonalEngagementPointVM[] = (engagementPointList) => {
    return engagementPointList.map((engagementPoint) => {
        return {
            ...engagementPoint,
            id: Math.random().toString(),
            date: parseISO(engagementPoint.date)
        };
    });
};

export const mapInsightsPersonalLoggedIn: (loggedInList: ILoggedInDTO[]) => ILoggedInVM[] = (loggedInList) => {
    return loggedInList.map((loggedIn) => {
        return {
            ...loggedIn,
            id: loggedIn.date,
            date: parseISO(loggedIn.date)
        };
    });
};

export const mapInsightsOrganizationSkillData: (skillData: string) => IInsightsOrganizationSkillVM[] = (skillData) => {
    const insightOrganizationData: IInsightsOrganizationSkillVM[] = [];
    const parsedRows = d3.csvParseRows(skillData);
    if (parsedRows.length < 1) return insightOrganizationData;
    const columnNamesRow = parsedRows[0];
    if (columnNamesRow.length < 13) return insightOrganizationData;
    parsedRows.slice(1).forEach((row) => {
        const date = parse(row[0].toString(), 'M/dd/yyyy', new Date());
        const item: IInsightsOrganizationSkillVM = {
            id: row[2],
            date,
            userName: row[1],
            userNuliaGuid: row[2],
            department: row[3],
            region: row[4],
            userAchieved: parseInt(row[5]),
            producerAchieved: parseInt(row[6]),
            masterAchieved: parseInt(row[7]),
            skillScore: parseInt(row[8]),
            assigned: parseInt(row[9]),
            inProgress: parseInt(row[10]),
            attained: parseInt(row[11]),
            needsAttention: parseInt(row[12]),
            skillName: row[13],
            outcomeName: row[14]
        };
        insightOrganizationData.push(item);
    });
    return insightOrganizationData;
};

export const mapAssessmentReportByDate: (data: string) => IAssessmentReportItemVM[] = (data) => {
    const insightAssessmentData: IAssessmentReportItemVM[] = [];
    const parsedRows = d3.csvParseRows(data);
    if (parsedRows.length < 1) return insightAssessmentData;
    const columnNamesRow = parsedRows[0];
    if (columnNamesRow.length < 17) return insightAssessmentData;
    parsedRows.slice(1).forEach((row) => {
        const reportDate = parse(row[0].toString(), 'M/dd/yyyy', new Date());
        const item: IAssessmentReportItemVM = {
            id: Math.floor(Math.random() * 10000).toString(),
            reportDate,
            department: row[1],
            skillId: parseInt(row[2]),
            skillName: row[3],
            application: row[4],
            scoreTargetLow: parseInt(row[5]),
            scoreTargetHigh: parseInt(row[6]),
            adoptionTarget: parseInt(row[7]),
            scoreAll: parseInt(row[8]),
            scoreLicensed: parseInt(row[9]),
            scoreUnlicensed: parseInt(row[10]),
            adoptionAll: parseInt(row[11]),
            adoptionLicensed: parseInt(row[12]),
            adoptionUnlicensed: parseInt(row[13]),
            headCountAll: parseInt(row[14]),
            headCountLicensed: parseInt(row[15]),
            headCountUnlicensed: parseInt(row[16])
        };
        insightAssessmentData.push(item);
    });
    return insightAssessmentData;
};

export const mapHowToCategories: (howToCategories: IHowToCategoryDTO[]) => IHowToCategoryVM[] = (howToCategories) => {
    return howToCategories.map((howTo) => {
        return {
            ...howTo
        };
    });
};

export const mapHowToPlaylist: (howToPlaylist: IHowToPlaylistDTO[]) => IHowToPlaylistVM[] = (howToPlaylist) => {
    return howToPlaylist.map((playlist) => {
        return {
            ...playlist,
            type: playlist.type as EHowToPlaylistType
        };
    });
};

export const mapAchievements: (achievementGroup: IAchievementGroupDTO) => IAchievementGroupVM = (achievementGroup) => {
    return {
        ...achievementGroup,
        achievements: achievementGroup.achievements.map((achievement) => {
            return {
                ...achievement,
                id: Math.random().toString(),
                awardedDate: achievement.awardedDate ? parseISO(achievement.awardedDate) : undefined,
                state: achievement.state as EAchievementState
            };
        }),
        group: EShowcaseAchievementCategory.EARNED
    };
};

export const mapAchievementsOverview: (
    achievementGroups: IAchievementsOverviewResponseDTO
) => IAchievementOverviewGroupVM[] = (achievementOverviewGroup) => {
    const mapAchievementGroup: (
        title: string,
        state: EAchievementState,
        achievementGroupItems: IAchievementOverviewDTO[] | undefined
    ) => IAchievementOverviewGroupVM = (title, state, achievementGroupItems) => {
        if (!achievementGroupItems) {
            return {
                achievements: [],
                title: title,
                description: title,
                state
            };
        }
        return {
            achievements: achievementGroupItems.map((achievement) => {
                return {
                    ...achievement,
                    category: achievement.category as EAchievementCategory,
                    state: state,
                    lastEarned: achievement.lastEarned ? parseISO(achievement.lastEarned) : undefined
                };
            }),
            title: title,
            description: title,
            state: state
        };
    };

    const result: IAchievementOverviewGroupVM[] = [];
    result.push(mapAchievementGroup('Awarded', EAchievementState.AWARDED, achievementOverviewGroup.achieved));
    result.push(mapAchievementGroup('In Progress', EAchievementState.IN_PROGRESS, achievementOverviewGroup.inProgress));
    result.push(mapAchievementGroup('Not Started', EAchievementState.NOT_STARTED, achievementOverviewGroup.notStarted));

    return result;
};

export const mapInsightsOrganizationalBadges: (data: IOrganizationalBadgeDTO[]) => IOrganizationalBadgeVM[] = (
    data
) => {
    return data.map((item) => ({
        id: `${item.outcomeId}-${item.level}`,
        ...item,
        badgeName: `${item.outcomeName} - ${item.level}`
    }));
};

export const mapInsightsOrganizationalUsersForBadges: (data: string) => IOrganizationalUserForBadgeVM[] = (data) => {
    const insightOrganizationalUserBadge: IOrganizationalUserForBadgeVM[] = [];
    const parsedRows = d3.csvParseRows(data);
    if (parsedRows.length < 1) return insightOrganizationalUserBadge;
    const columnNamesRow = parsedRows[0];
    if (columnNamesRow.length < 9) return insightOrganizationalUserBadge;
    parsedRows.slice(1).forEach((row, i) => {
        const date = parse(row[0].toString(), 'M/dd/yyyy', new Date());
        const item: IOrganizationalUserForBadgeVM = {
            id: row[1],
            date,
            userId: row[1],
            displayName: row[2],
            department: row[3],
            region: row[4],
            shared: parseInt(row[5]),
            claimed: parseInt(row[6]),
            readyToClaim: parseInt(row[7]),
            needAttention: parseInt(row[8]),
            outcomeName: row[9],
            level: row[10]
        };
        insightOrganizationalUserBadge.push(item);
    });
    return insightOrganizationalUserBadge;
};

export const mapShowcaseBadges: (showcaseBadges: IGetShowcaseBadgeResponseDTO) => IGetShowcaseBadgeResponseVM = (
    showcaseBadges
) => {
    const convertDTOtoVM = (showcaseBadges: IShowcaseBadgeDTO[]) => {
        return showcaseBadges.map((badge) => {
            return {
                ...badge,
                claimedDate: badge.claimedDate ? parseISO(badge.claimedDate) : undefined,
                sharedDate: badge.sharedDate ? parseISO(badge.sharedDate) : undefined,
                verifiedDate: badge.verifiedDate ? parseISO(badge.verifiedDate) : undefined,
                state: badge.state as EBadgeLevel,
                inProgressSkillStates: badge.inProgressSkillStates
                    ? badge.inProgressSkillStates.map((skillState) => {
                          return skillState as ECompetenceState;
                      })
                    : undefined,
                level: badge.level as EShowcaseBadgeLevel
            };
        });
    };
    return {
        claimableBadges: convertDTOtoVM(showcaseBadges.claimableBadges),
        claimedBadges: convertDTOtoVM(showcaseBadges.claimedBadges),
        inProgressBadges: convertDTOtoVM(showcaseBadges.inProgressBadges),
        needsAttentionBadges: convertDTOtoVM(showcaseBadges.needsAttentionBadges),
        notSharedBadges: convertDTOtoVM(showcaseBadges.notSharedBadges)
    };
};

export const mapUserRankings: (userRankings: IUserRankingsResponseDTO) => IUserRankingsVM[] = (userRankings) => {
    const convertDTOtoVM: (userRanking: IUserRankingDTO) => IUserRankingVM = (userRanking) => {
        return {
            ...userRanking,
            skillsMaintainedMedal: userRanking.skillsMaintainedMedal as ERankingMedal,
            badgesClaimedMedal: userRanking.badgesClaimedMedal as ERankingMedal,
            achievementsEarnedMedal: userRanking.achievementsEarnedMedal as ERankingMedal,
            engagementPointMedal: userRanking.engagementPointMedal as ERankingMedal,
            badgesSharedMedal: userRanking.badgesSharedMedal as ERankingMedal
        };
    };
    const calculateIsDataEmpty: (userRankingData: IUserRankingDTO) => boolean = (userRankingData) => {
        return (
            !userRankingData.achievementsEarnedRank &&
            !userRankingData.badgesClaimedRank &&
            !userRankingData.badgesSharedRank &&
            !userRankingData.engagementPointRank &&
            !userRankingData.skillsMaintainedRank
        );
    };
    const rankingsData: IUserRankingsVM[] = [];
    if (userRankings.globalRanking) {
        const isDataEmpty = calculateIsDataEmpty(userRankings.globalRanking);
        !isDataEmpty &&
            rankingsData.push({
                title: 'Nuliaverse rankings',
                type: EUserRanking.GLOBAL,
                ranking: userRankings.globalRanking ? convertDTOtoVM(userRankings.globalRanking) : undefined
            });
    }
    if (userRankings.organizationRanking) {
        const isDataEmpty = calculateIsDataEmpty(userRankings.organizationRanking);
        !isDataEmpty &&
            rankingsData.push({
                title: 'Organization rankings',
                type: EUserRanking.ORGANIZATION,
                ranking: userRankings.organizationRanking ? convertDTOtoVM(userRankings.organizationRanking) : undefined
            });
    }
    if (userRankings.departmentRanking) {
        const isDataEmpty = calculateIsDataEmpty(userRankings.departmentRanking);
        !isDataEmpty &&
            rankingsData.push({
                title: 'Department rankings',
                type: EUserRanking.DEPARTMENT,
                ranking: userRankings.departmentRanking ? convertDTOtoVM(userRankings.departmentRanking) : undefined
            });
    }
    return rankingsData;
};

export const mapTenantStatus: (tenantStatus: ITenantStatusDTO) => ITenantStatusVM = (tenantStatus) => {
    return {
        ...tenantStatus,
        anonymizedReports: tenantStatus.anonymizedReports as EAnonymizedReportStatus,
        lastScoreDate: parseISO(tenantStatus.lastScoreDate),
        anonymizedReportsState: tenantStatus.anonymizedReportsState as ETenantStatusColor,
        lastScoreDateState: tenantStatus.lastScoreDateState as ETenantStatusColor,
        overallState: tenantStatus.overallState as ETenantStatusColor,
        usersScoredState: tenantStatus.usersScoredState as ETenantStatusColor,
        recommendation: tenantStatus.recommendation
    };
};
