import styled from '../../theme/styled';

export const RootBox = styled('div', {
    shouldForwardProp: (prop) => prop !== 'valueSidePosition'
})<{ valueSidePosition: 'left' | 'right' }>(({ valueSidePosition }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: 30,
    position: 'relative'
}));

export const RatingBox = styled('div')(({ theme }) => ({
    paddingRight: 4,
    color: theme.palette.primary.main,
    fontWeight: 500,
    minWidth: 34,
    fontSize: 14,
    letterSpacing: '1.25px'
}));

export const LeftRatingBox = styled(RatingBox)({
    minWidth: 25,
    paddingTop: '4px',
    textAlign: 'right'
});

export const RightRatingBox = styled(RatingBox, {
    shouldForwardProp: (prop) => prop !== 'paddingLeft'
})<{ paddingLeft?: number }>(({ paddingLeft }) => ({
    minWidth: '90px',
    position: 'absolute',
    left: paddingLeft,
    paddingTop: '4px'
}));
