import { FC } from 'react';

const SkillBestPractice: FC = () => {
    return (
        <svg
            id='graph-svgbp-assigned-5-0'
            width='68'
            height='74'
            viewBox='0 0 75 79'
            fill='rgb(229, 229, 229)'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g id='doughnut-chart-bp-assigned-5-0'>
                <g transform='translate(40, 40)'>
                    <path
                        d='M0,-34A34,34,0,1,1,0,34A34,34,0,1,1,0,-34M0,-16A16,16,0,1,0,0,16A16,16,0,1,0,0,-16Z'
                        stroke='rgb(0, 0, 0)'
                        strokeWidth='2'
                        fill='rgb(229, 229, 229)'
                    ></path>
                </g>
                <circle id='colored-fill' r='15' cx='40' cy='40' strokeWidth='0' fill='rgb(255, 255, 255)'></circle>
                <g id='value' transform='translate(29, 44)' fontSize='14' fontWeight='700' color='#352957'>
                    <text fontFamily='"Open Sans", Arial, sans-serif' fill='#352957' letterSpacing='0.25px'>
                        0/5
                    </text>
                </g>
                <g transform='translate(40, 40)'>
                    <path
                        d='M0,-33L0,-17Z'
                        id='main-gbp-assigned-5-0'
                        stroke='rgb(0, 0, 0)'
                        strokeWidth='0'
                        fill='rgb(255, 204, 85)'
                    ></path>
                    <path
                        d='M0,-33A33,33,0,1,1,0,33A33,33,0,1,1,0,-33M0,-17A17,17,0,1,0,0,17A17,17,0,1,0,0,-17Z'
                        id='main-gbp-assigned-5-0'
                        stroke='rgb(0, 0, 0)'
                        strokeWidth='0'
                        fill='rgb(229, 229, 229)'
                    ></path>
                </g>
                <line
                    id='line-bp-assigned-5-0'
                    x2='40'
                    y2='24'
                    x1='40'
                    y1='7'
                    strokeWidth='1'
                    stroke='rgb(0, 0, 0)'
                    fill='rgb(0, 0, 0)'
                    opacity='0.9'
                ></line>
            </g>
        </svg>
    );
};

export default SkillBestPractice;
