import { createContext, FC, useContext, PropsWithChildren } from 'react';
import useInsightsTimespanOption from '../hooks/useInsightsTimespanOption';
import { EGraphCardSelect } from '../interfaces/enums/EGraphCardSelect';

export interface IInsightsOrganizationContext {
    selectedTimespanOption: EGraphCardSelect;
    handleSelectedTimespanOptionChange: (value: string | string[]) => void;
}

export const InsightsOrganizationContext = createContext<IInsightsOrganizationContext>(
    {} as IInsightsOrganizationContext
);

interface IProps {}

export const InsightsOrganizationProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const { selectedTimespanOption, handleSelectedTimespanOptionChange } = useInsightsTimespanOption({
        initialTimespanOptionValue:
            (localStorage.getItem('timespan_organization_insights') as EGraphCardSelect) || EGraphCardSelect.MONTH_3,
        timespanOptionValueChangeCallback(value) {
            localStorage.setItem('timespan_organization_insights', value);
        }
    });

    const insightsOrganizationContext: IInsightsOrganizationContext = {
        handleSelectedTimespanOptionChange,
        selectedTimespanOption
    };

    return (
        <InsightsOrganizationContext.Provider value={insightsOrganizationContext}>
            {children}
        </InsightsOrganizationContext.Provider>
    );
};

export const useInsightsOrganizationStateValue: () => IInsightsOrganizationContext = () =>
    useContext(InsightsOrganizationContext);
