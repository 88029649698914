import { createContext, FC, useContext, PropsWithChildren, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
    useGetInsightsOrganizationaUserSummaryDataQuery,
    useGetInsightsPersonalDataQuery
} from '../services/InsightsQueryService';
import { IInsightsPersonalVM } from '../interfaces/views/IInsightsPersonalVM';
import { IInsightsPersonalSkillGraphVM } from '../interfaces/views/IInsightsPersonalSkillGraphVM';
import { IInsightsPersonalOutcomeGraphVM } from '../interfaces/views/IInsightsPersonalOutcomeGraphVM';
import { IInsightsPersonalEngagementGraphVM } from '../interfaces/views/IInsightsPersonalEngagementGraphVM';
import { IInsightsPersonalBadgeGraphVM } from '../interfaces/views/IInsightsPersonalBadgeGraphVM';
import { useApiStateValue } from './ApiContext';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';
import useInsightsTimespanOption from '../hooks/useInsightsTimespanOption';
import { EGraphCardSelect } from '../interfaces/enums/EGraphCardSelect';

export interface IInsightsPersonalContext {
    data: IInsightsPersonalVM[];
    getInsightsPersonalSkills: () => IInsightsPersonalSkillGraphVM[];
    getInsightsPersonalOutcomes: () => IInsightsPersonalOutcomeGraphVM[];
    getInsightsPersonalEngagement: () => IInsightsPersonalEngagementGraphVM[];
    getInsightsPersonalBadges: () => IInsightsPersonalBadgeGraphVM[];
    selectedTimespanOption: EGraphCardSelect;
    handleSelectedTimespanOptionChange: (value: string | string[]) => void;
}

export const InsightsPersonalContext = createContext<IInsightsPersonalContext>({} as IInsightsPersonalContext);

interface IProps {}

export const InsightsPersonalProvider: FC<PropsWithChildren<IProps>> = ({ children }) => {
    const { id: paramsId } = useParams();
    const { data: fetchedData, refetch: refetchPersonalData } = useGetInsightsPersonalDataQuery();
    const { data: fetchedOrganizationalUserSummaryData, refetch: refetchOrganizationalUserData } =
        useGetInsightsOrganizationaUserSummaryDataQuery(paramsId);
    const [data, setData] = useState<IInsightsPersonalVM[]>([]);
    const { invalidateQueryCache } = useApiStateValue();
    const { selectedTimespanOption, handleSelectedTimespanOptionChange } = useInsightsTimespanOption({
        initialTimespanOptionValue:
            (localStorage.getItem('timespan_personal_insights') as EGraphCardSelect) || EGraphCardSelect.MONTH_3,
        timespanOptionValueChangeCallback(value) {
            localStorage.setItem('timespan_personal_insights', value);
        }
    });

    useEffect(() => {
        if (paramsId) {
            invalidateQueryCache(EApiQueryKey.INSIGHTS_FETCH_PERSONAL_DATA);
            refetchOrganizationalUserData();
        } else {
            invalidateQueryCache(EApiQueryKey.INSIGHTS_FETCH_ORGANIZATION_USER_SUMMARIES);
            refetchPersonalData();
        }
    }, [paramsId]);

    const getInsightsPersonalSkills: () => IInsightsPersonalSkillGraphVM[] = () => {
        if (data)
            return data.map((item) => {
                return {
                    date: item.date,
                    skillsAssigned: item.skillsAssigned,
                    skillsAttained: item.skillsAttained,
                    skillsNeedAttention: item.skillsNeedAttention,
                    skillsInProgress: item.skillsInProgress
                };
            });
        return [];
    };

    const getInsightsPersonalOutcomes: () => IInsightsPersonalOutcomeGraphVM[] = () => {
        if (data)
            return data.map((item) => {
                return {
                    date: item.date,
                    outcomesAssigned: item.outcomesAssigned,
                    outcomesAttained: item.outcomesAttained,
                    outcomesNeedAttention: item.outcomesNeedAttention,
                    outcomesInProgress: item.outcomesInProgress
                };
            });
        return [];
    };

    const getInsightsPersonalEngagement: () => IInsightsPersonalEngagementGraphVM[] = () => {
        if (data)
            return data.map((item) => {
                return {
                    date: item.date,
                    engagementPoints: item.engagementPoints,
                    logins: item.logins
                };
            });
        return [];
    };

    const getInsightsPersonalBadges: () => IInsightsPersonalBadgeGraphVM[] = () => {
        if (data)
            return data.map((item) => {
                return {
                    date: item.date,
                    badgesClaimed: item.badgesClaimed,
                    badgesNeedAttention: item.badgesNeedAttention,
                    badgesReady: item.badgesReady,
                    badgesShared: item.badgesShared
                };
            });
        return [];
    };

    useEffect(() => {
        if (fetchedData) {
            setData(fetchedData);
        }
    }, [fetchedData]);

    useEffect(() => {
        if (fetchedOrganizationalUserSummaryData) {
            setData(fetchedOrganizationalUserSummaryData);
        }
    }, [fetchedOrganizationalUserSummaryData]);

    const insightsPersonalContext: IInsightsPersonalContext = {
        data,
        getInsightsPersonalSkills,
        getInsightsPersonalOutcomes,
        getInsightsPersonalEngagement,
        getInsightsPersonalBadges,
        handleSelectedTimespanOptionChange,
        selectedTimespanOption
    };

    return (
        <InsightsPersonalContext.Provider value={insightsPersonalContext}>{children}</InsightsPersonalContext.Provider>
    );
};

export const useInsightsPersonalStateValue: () => IInsightsPersonalContext = () => useContext(InsightsPersonalContext);
