import { useQuery, UseQueryResult, UseMutationResult, useMutation } from '@tanstack/react-query';
import { getTokenAndHeaders } from './helpers/apiUtil';
import useAuthParams from '../hooks/useAuthParams';
import { IAuthParams } from '../interfaces/IAuthParams';
import { IAssignmentDTO } from '../interfaces/dtos/IAssignmentDTO';
import { mapAdminOutcomes, mapSingleUserOutcome, mapUserOutcomes } from './helpers/mappers';
import { IUserOutcomeVM } from '../interfaces/views/IUserOutcomeVM';
import { ISingleUserOutcomeVM } from '../interfaces/views/ISingleUserOutcomeVM';
import { EMasterAction } from '../interfaces/enums/EMasterAction';
import { apiInstance } from './helpers/apiInstance';
import { IUserOutcomesSnapshotDTO } from '../interfaces/dtos/IUserOutcomesSnapshotDTO';
import { IAdminOutcomeVM } from '../interfaces/views/IAdminOutcomeVM';
import { EApiQueryKey } from '../interfaces/enums/EApiQueryKey';

export interface IPostAssignOutcomeRequest {
    assignmentType: number;
    outcomeId: string;
    isRecommendation?: boolean;
}

export interface IPostMasterOutcomeRequest {
    outcomeId: number;
    action: EMasterAction;
    isRecommendation?: boolean;
}

export interface IPostMasterOutcomeResponse {}

export interface IPostBulkAssignOutcomeRequest {
    outcomeIds: number[];
    targetUserIds: string[];
    assignmentType: string;
}

export interface IPostChangeCompanyFocusRequest {
    outcomeId: number;
    companyFocus: boolean;
}

export interface IPostSetMasterLevelRequest {
    outcomeId: number;
    action: ESetMasterLevelAction;
    targetUserId: string;
}

export enum ESetMasterLevelAction {
    ENABLE = 'Enable',
    DISABLE = 'Disable'
}

export const getOutcome = async (authParams: IAuthParams, outcomeId?: string) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`useroutcomes/${outcomeId}`, headers);
    return mapSingleUserOutcome(data);
};

export const useGetOutcomeQuery: (outcomeId?: string) => UseQueryResult<ISingleUserOutcomeVM, Error> = (outcomeId) => {
    const response = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.OUTCOME_FETCH, outcomeId],
        queryFn: async () => {
            return getOutcome(response, outcomeId);
        },
        refetchOnWindowFocus: false,
        enabled: false
    });
};

export const getAllOutcomes = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`useroutcomes`, headers);
    return mapUserOutcomes(data);
};

export const useGetAllOutcomesQuery: () => UseQueryResult<IUserOutcomeVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.OUTCOME_FETCH_ALL],
        queryFn: async () => {
            return getAllOutcomes(authParams);
        },
        refetchOnWindowFocus: false
    });
};

// POST ASSIGN OUTCOME
export const postAssignOutcome: (
    variables: IPostAssignOutcomeRequest & { authParams: IAuthParams },
    isRecommendation?: boolean
) => Promise<IAssignmentDTO> = async (variables) => {
    const { authParams, assignmentType, outcomeId, isRecommendation } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.post(
        `outcomes/${outcomeId}/assignment${isRecommendation ? '?rec=true' : ''}`,
        {
            assignmentType
        },
        headers
    );
    return data;
};

export const usePostAssignOutcome: () => UseMutationResult<IAssignmentDTO, Error, IPostAssignOutcomeRequest> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostAssignOutcomeRequest) => {
            return postAssignOutcome({ ...variables, authParams });
        }
    });
};

// POST MASTER
export const postMasterOutcome: (
    variables: IPostMasterOutcomeRequest & { authParams: IAuthParams }
) => Promise<IPostMasterOutcomeResponse> = async (variables) => {
    const { authParams, outcomeId, action, isRecommendation } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.post(
        `outcomes/${outcomeId}/master-level${isRecommendation ? '?rec=true' : ''}`,
        {
            action
        },
        headers
    );
    return data;
};

export const usePostMasterOutcome: () => UseMutationResult<
    IPostMasterOutcomeResponse,
    Error,
    IPostMasterOutcomeRequest
> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostMasterOutcomeRequest) => {
            return postMasterOutcome({ ...variables, authParams });
        }
    });
};

// GET USER SKILLS SNAPSHOT
export const getUserOutcomesSnapshot = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);

    const {
        data: { data }
    } = await apiInstance.get(`userOutcomeSnapshot`, headers);

    return data;
};

export const useGetUserOutcomesSnapshot: () => UseQueryResult<IUserOutcomesSnapshotDTO, Error> = () => {
    const authParams = useAuthParams();

    return useQuery({
        queryKey: [EApiQueryKey.OUTCOME_FETCH_USE_SNAPSHOT],
        queryFn: async () => {
            return getUserOutcomesSnapshot(authParams);
        },
        refetchOnWindowFocus: false
    });
};

// GET ADMIN OUTCOME
export const getAllAdminOutcomes = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const {
        data: { data }
    } = await apiInstance.get(`tenant/userOutcomes`, headers);
    return mapAdminOutcomes(data);
};

export const useGetAllAdminOutcomesQuery: () => UseQueryResult<IAdminOutcomeVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.OUTCOME_FETCH_ALL_ADMIN],
        queryFn: async () => {
            return getAllAdminOutcomes(authParams);
        },
        refetchOnWindowFocus: false
    });
};

// POST BULK ASSIGN OUTCOME
export const postBulkAssignOutcome: (
    variables: IPostBulkAssignOutcomeRequest & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { authParams, assignmentType, outcomeIds, targetUserIds } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    await apiInstance.post(
        `bulk/outcomes/assignment`,
        {
            assignmentType,
            outcomeIds,
            targetUserIds
        },
        headers
    );
    return undefined;
};

export const usePostBulkAssignOutcome: () => UseMutationResult<
    undefined,
    Error,
    IPostBulkAssignOutcomeRequest
> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostBulkAssignOutcomeRequest) => {
            return postBulkAssignOutcome({ ...variables, authParams });
        }
    });
};

// POST SET COMPANY FOCUS ORGANIZATIONAL OUTCOME
export const postChangeCompanyFocus: (
    variables: IPostChangeCompanyFocusRequest & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { authParams, outcomeId, companyFocus } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    await apiInstance.post(
        `outcomes/${outcomeId}/company-focus`,
        {
            companyFocus
        },
        headers
    );
    return undefined;
};

export const usePostChangeCompanyFocus: () => UseMutationResult<
    undefined,
    Error,
    IPostChangeCompanyFocusRequest
> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostChangeCompanyFocusRequest) => {
            return postChangeCompanyFocus({ ...variables, authParams });
        }
    });
};

// POST SET COMPANY FOCUS ORGANIZATIONAL OUTCOME TO USER
export const postSetMasterLevelFocusToUser: (
    variables: IPostSetMasterLevelRequest & { authParams: IAuthParams }
) => Promise<undefined> = async (variables) => {
    const { authParams, targetUserId, outcomeId, action } = variables;
    const { headers } = await getTokenAndHeaders(authParams);

    await apiInstance.post(
        `userOutcomes/${targetUserId}/master-level-required`,
        {
            outcomeId,
            action
        },
        headers
    );
    return undefined;
};

export const usePostSetMasterLevelFocusToUser: () => UseMutationResult<
    undefined,
    Error,
    IPostSetMasterLevelRequest
> = () => {
    const authParams = useAuthParams();
    return useMutation({
        mutationFn: (variables: IPostSetMasterLevelRequest) => {
            return postSetMasterLevelFocusToUser({ ...variables, authParams });
        }
    });
};
