import { FC, useCallback, useEffect, useMemo, useState, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton, useMediaQuery, useTheme } from '@mui/material';
import Activity from './components/activity/Activity';
import BehaviorLevel from './components/behavior-level/BehaviorLevel';
import SkillGraphCard from './components/skill-graph-card/SkillGraphCard';
import { EPages } from '../../../interfaces/enums/EPages';
import { EBehaviorLevel } from '../../../interfaces/enums/EBehaviorLevel';
import { UserSkillProgressDTO } from '../../../interfaces/dtos/UserSkillProgressDTO';
import { IActivity } from '../../../interfaces/IActivity';
import { IBehavior } from '../../../interfaces/IBehavior';
import PageTitle from '../../../ui/general/page-title/PageTitle';
import Loading from '../../../ui/general/loading/Loading';
import AboutCard from '../../../ui/cards/about-card/AboutCard';
import { ESkillLevel } from '../../../interfaces/enums/ESkillLevel';
import { useSkillStateValue } from '../../../contexts/SkillContext';
import NoDataCard from '../../../ui/cards/no-data-card/NoDataCard';
import { StyledTab, StyledTabs } from '../../../css/CommonComponents';
import SomethingWentWrongCard from '../../../ui/cards/something-went-wrong-card/SomethingWentWrongCard';
import TabPanel from '../../../ui/general/tab-panel/TabPanel';
import {
    RootInnerBox,
    AboutSkillContentWrapper,
    ActivitiesBox,
    ActivitiesInnerBox,
    BehaviorsActivitiesWrapper,
    BehaviorsBox,
    BehaviorsTitleBox,
    GraphCardWrapper,
    SkillDetailsWrapper,
    SkillsInfoPaper,
    ActivitiesTabCountSpan,
    ActivitiesTabTitleTypography,
    ActivitiesTabWrapper,
    SeeAllWrapper,
    SeeAllTypography,
    TabsWrapper,
    RootBox,
    ActivitiesTitleSpan,
    BehaviorsTitleSpanLgDown
} from './Style';
import { useUserAuthStateValue } from '../../../contexts/UserAuthContext';
import { ETenantPreference } from '../../../interfaces/enums/ETenantPreference';
import { ESkillType } from '../../../interfaces/enums/ESkillType';
import StandardProgressDoughnutChart from '../../../ui/general/progress-doughnut-chart/StandardProgressDoughnutChart';
import BestPracticeProgressDoughnutChart from '../../../ui/general/progress-doughnut-chart/BestPracticeProgressDoughnutChart';

interface IProps {
    page?: EPages;
}

const Skill: FC<IProps> = ({ page }) => {
    const {
        selectedBehavior,
        setSelectedBehavior,
        selectedBehaviorLevel,
        setSelectedBehaviorLevel,
        toggleUserAssignment,
        skillData: userSkillData,
        userSkillProgressData,
        refetchUserSkillProgress,
        isLoadingSkillData,
        isUserSkillProgressLoading,
        toggleUserFavorite,
        mutatePostFavoriteLoading,
        isErrorFetchUserSkillData,
        isErrorFetchUserSkillProgress,
        refetchSkillData,
        updateActivityLastTriedData,
        totalNumberOfActivities,
        showAllActivitiesHandler,
        sendContentUsageRequest,
        bpTotalSteps,
        bpAchievedSteps
    } = useSkillStateValue();
    const { isTenantPreferenceActive } = useUserAuthStateValue();
    const { setBPSkill } = useUserAuthStateValue();

    const { t } = useTranslation();
    const [isMaster, setMaster] = useState<boolean>(false);
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const [selectedTabValue, setSelectedTabValue] = useState<number>(0);

    useEffect(() => {
        setBPSkill(!!userSkillData?.isBestPracticeType);
    }, [userSkillData?.isBestPracticeType]);

    useEffect(() => {
        setMaster(userSkillData?.assignment.level === ESkillLevel.MASTER);
    }, [userSkillData]);

    const calculateExpectedProgressDoughnutValue = useCallback(() => {
        if (userSkillData?.isBestPracticeType) return undefined;
        if (userSkillData?.isStandardType) {
            if (userSkillData.assignment.level === ESkillLevel.MASTER) {
                if (userSkillData.maxLevel === (ESkillLevel.MASTER as string))
                    return userSkillProgressData?.scoreDetail.targetMaster;
                return userSkillProgressData?.scoreDetail.targetProducer;
            }
            if (userSkillData.assignment.level === ESkillLevel.PRODUCER)
                return userSkillProgressData?.scoreDetail.targetProducer;
        }
        return undefined;
    }, [userSkillData, userSkillProgressData]);

    const progressDoughnutChartMemo = useMemo(() => {
        if (userSkillData?.assignment) {
            if (
                userSkillProgressData?.scoreDetail.score !== null &&
                userSkillProgressData?.scoreDetail.score !== undefined
            )
                if (userSkillData.isStandardType) {
                    return (
                        <StandardProgressDoughnutChart
                            index={0}
                            key={`pdc-${isMaster}`}
                            currentValue={userSkillProgressData?.scoreDetail.score || 0}
                            expectedValue={calculateExpectedProgressDoughnutValue()}
                            dimension={{
                                width: 80,
                                height: 80,
                                margin: {
                                    bottom: 5,
                                    left: 10,
                                    right: 10,
                                    top: 7
                                },
                                starSize: 32,
                                starX: -8,
                                starY: -10
                            }}
                            isAssigned={userSkillData.assignment.level !== ESkillLevel.NONE}
                            skillStatus={userSkillData.progressDoughnutChartType}
                        />
                    );
                } else {
                    if (bpAchievedSteps !== undefined && bpTotalSteps !== undefined)
                        return (
                            <BestPracticeProgressDoughnutChart
                                index={0}
                                key={`pdc-${isMaster}`}
                                achievedStepNumber={bpAchievedSteps}
                                totalStepNumber={bpTotalSteps}
                                dimension={{
                                    width: 80,
                                    height: 80,
                                    margin: {
                                        bottom: 5,
                                        left: 10,
                                        right: 10,
                                        top: 7
                                    },
                                    starSize: 32,
                                    starX: -8,
                                    starY: -10
                                }}
                                isAssigned={userSkillData.assignment.level !== ESkillLevel.NONE}
                                skillStatus={userSkillData.progressDoughnutChartType}
                                isDataMissing={
                                    !userSkillData.userActivitySummary || !userSkillData.userActivitySummary?.total
                                }
                            />
                        );
                    return <></>;
                }
        }
        return <Skeleton variant='circular' width={70} height={70} />;
    }, [
        userSkillProgressData,
        userSkillData,
        isMaster,
        bpTotalSteps,
        bpAchievedSteps,
        calculateExpectedProgressDoughnutValue
    ]);

    const onBehaviorGroupSelect = (id: EBehaviorLevel, expanded: boolean) => {
        if (id !== selectedBehaviorLevel) {
            setSelectedBehavior(null);
            setSelectedBehaviorLevel(id);
        } else setSelectedBehaviorLevel(null);
    };

    const activitiesToDisplay = useMemo(() => {
        if (selectedBehavior) {
            return selectedBehavior.activities;
        } else {
            const activitiesToDisplay: IActivity[] = [];
            let behaviorsToFilterFrom: IBehavior[][] = [];
            if (selectedBehaviorLevel === EBehaviorLevel.BASIC && userSkillData?.userBehaviors)
                behaviorsToFilterFrom = [userSkillData.userBehaviors.behaviors];
            else if (selectedBehaviorLevel === EBehaviorLevel.CORE && userSkillData?.producerBehaviors.behaviors)
                behaviorsToFilterFrom = [userSkillData.producerBehaviors.behaviors];
            else if (selectedBehaviorLevel === EBehaviorLevel.BONUS && userSkillData?.masterBehaviors.behaviors)
                behaviorsToFilterFrom = [userSkillData.masterBehaviors.behaviors];
            else
                behaviorsToFilterFrom = [
                    userSkillData?.userBehaviors.behaviors || [],
                    userSkillData?.producerBehaviors.behaviors || [],
                    userSkillData?.masterBehaviors?.behaviors || []
                ];
            behaviorsToFilterFrom.forEach((behavior) => {
                behavior?.forEach((behaviorItem) => {
                    activitiesToDisplay.push(...behaviorItem.activities);
                });
            });
            return activitiesToDisplay;
        }
    }, [userSkillData, selectedBehaviorLevel, selectedBehavior]);

    const activitiesMemo = useMemo(() => {
        const r = activitiesToDisplay;
        let container = document.querySelector('.activities-inner-box.not-empty');
        if (container) {
            (container as HTMLElement).style.transition = 'none';
            (container as HTMLElement).style.opacity = '0';
            container.classList.add('preAnimation');
            setTimeout(function () {
                if (container) {
                    (container as HTMLElement).style.transition = 'all 300ms';
                    (container as HTMLElement).style.opacity = '1';
                    container.classList.remove('preAnimation');
                }
            }, 100);
        }

        return [...new Map(r.map((m) => [m.id, m])).values()].map((activity, index) => {
            return (
                <Activity
                    index={index}
                    id={activity.id.toString()}
                    isPopupMode={false}
                    key={Math.random()}
                    title={activity.title}
                    type={activity.type}
                    url={activity.url}
                    description={activity.description}
                    lastLaunched={activity.lastLaunched}
                    icon={activity.icon}
                    appUrl={activity.appUrl}
                    updateActivityLastTriedData={updateActivityLastTriedData}
                />
            );
        });
    }, [selectedBehaviorLevel, selectedBehavior, activitiesToDisplay, updateActivityLastTriedData, userSkillData]);

    const userSkillState = useMemo(() => {
        if (userSkillData?.assignment.level === ESkillLevel.MASTER) return userSkillData.assignment.masterState;
        if (userSkillData?.assignment.level === ESkillLevel.PRODUCER) return userSkillData.assignment.producerState;
        return undefined;
    }, [userSkillData]);

    const behaviorsMemo = useMemo(() => {
        if (userSkillData?.isStandardType)
            return (
                <>
                    {userSkillData?.userBehaviors.behaviors.length === 0 &&
                    userSkillData?.masterBehaviors?.behaviors.length === 0 &&
                    userSkillData?.producerBehaviors?.behaviors.length === 0 ? (
                        <NoDataCard />
                    ) : (
                        <>
                            <BehaviorLevel
                                index={0}
                                isStandardSkillType
                                onBehaviorGroupSelect={onBehaviorGroupSelect}
                                id={EBehaviorLevel.BASIC}
                                title={t('components.behavior.user')}
                                behaviors={userSkillData?.userBehaviors.behaviors}
                                pointsEarned={userSkillData?.userBehaviors.score}
                                pointsMax={userSkillData?.userBehaviors.maxScore}
                                isSelected={EBehaviorLevel.BASIC === selectedBehaviorLevel}
                            />
                            {userSkillData?.producerBehaviors?.behaviors && (
                                <BehaviorLevel
                                    index={1}
                                    isStandardSkillType
                                    onBehaviorGroupSelect={onBehaviorGroupSelect}
                                    id={EBehaviorLevel.CORE}
                                    title={t('components.behavior.producer')}
                                    behaviors={userSkillData?.producerBehaviors?.behaviors}
                                    pointsEarned={userSkillData?.producerBehaviors?.score}
                                    pointsMax={userSkillData?.producerBehaviors?.maxScore}
                                    isSelected={EBehaviorLevel.CORE === selectedBehaviorLevel}
                                />
                            )}
                            {userSkillData?.masterBehaviors?.behaviors && (
                                <BehaviorLevel
                                    index={2}
                                    isStandardSkillType
                                    onBehaviorGroupSelect={onBehaviorGroupSelect}
                                    id={EBehaviorLevel.BONUS}
                                    title={t('components.behavior.master')}
                                    behaviors={userSkillData?.masterBehaviors?.behaviors}
                                    pointsEarned={userSkillData?.masterBehaviors?.score}
                                    pointsMax={userSkillData?.masterBehaviors?.maxScore}
                                    isSelected={EBehaviorLevel.BONUS === selectedBehaviorLevel}
                                />
                            )}
                        </>
                    )}
                </>
            );
        if (userSkillData?.producerBehaviors.behaviors.length === 0) return <NoDataCard />;
        const bpSkillGoal: IBehavior | undefined = userSkillData?.producerBehaviors.behaviors[0];
        if (bpSkillGoal) {
            return (
                <BehaviorLevel
                    index={1}
                    isStandardSkillType={false}
                    onBehaviorGroupSelect={onBehaviorGroupSelect}
                    id={EBehaviorLevel.CORE}
                    title={t('components.behavior.producer')}
                    behaviors={userSkillData?.producerBehaviors?.behaviors.slice(0, 1)}
                    pointsEarned={userSkillData?.producerBehaviors?.score}
                    pointsMax={userSkillData?.producerBehaviors?.maxScore}
                    isSelected={EBehaviorLevel.CORE === selectedBehaviorLevel}
                />
            );
        }
        return <></>;
    }, [userSkillData, onBehaviorGroupSelect]);

    const activitiesRootMemo = useMemo(() => {
        return (
            <>
                {userSkillData?.userBehaviors.behaviors.length === 0 &&
                userSkillData?.masterBehaviors?.behaviors?.length === 0 &&
                userSkillData?.producerBehaviors?.behaviors?.length === 0 ? (
                    <NoDataCard />
                ) : (
                    <ActivitiesInnerBox
                        isBestPracticeMode={userSkillData?.skillType === ESkillType.BEST_PRACTICE}
                        className={[
                            'activities-inner-box',
                            'activities-box',
                            userSkillData ? 'not-empty' : 'empty'
                        ].join(' ')}
                    >
                        {userSkillData && activitiesMemo}
                    </ActivitiesInnerBox>
                )}
            </>
        );
    }, [userSkillData, activitiesMemo]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTabValue(newValue);
    };

    const onHandleTabChangeKeyDown = (e: KeyboardEvent<any>) => {
        if (e.key === 'ArrowDown') {
            document.querySelectorAll('.behavior-item').forEach(function (element, index) {
                if (index === 0) {
                    (element as HTMLElement).focus();
                }
                element.setAttribute('tabIndex', '0');
            });
        }
    };

    return (
        <RootBox>
            <RootInnerBox>
                <PageTitle
                    title={userSkillData?.title}
                    assigned={{
                        assignerId: userSkillData?.assignment.assignerId,
                        isAssigned: userSkillData ? userSkillData?.isAssigned : undefined,
                        assignerName: userSkillData?.assignment.assignerName,
                        assignedDate: userSkillData?.assignment.date,
                        assignedTitle: t('skill.inYourSkills'),
                        notAssignedTitle: t('skill.notInYourSkills'),
                        assignTooltipText: t('skill.tooltips.assignSkill'),
                        unassignTooltipText: t('skill.tooltips.unassignSkill'),
                        assignerRole: userSkillData?.assignment.assignerRole,
                        streak: userSkillData?.assignment.streak,
                        state: userSkillState
                    }}
                    toggleAssign={toggleUserAssignment}
                    leftSectionJSX={progressDoughnutChartMemo}
                    isError={isErrorFetchUserSkillData}
                />
                <AboutCard
                    loading={isLoadingSkillData}
                    title={t('skill.contentCard.title')}
                    appIcons={userSkillData?.apps}
                    description={userSkillData?.description ?? ''}
                    videoImage={userSkillData?.videoImage}
                    videoUrl={userSkillData?.videoUrl}
                    isUserFocus={userSkillData?.isUserFavorite}
                    isUserFavoriteControlVisible
                    isUserFavoriteControlDisabled={userSkillData?.isUserFavoriteControlDisabled}
                    toggleUserFavorite={toggleUserFavorite}
                    isToggleUserFavoriteRequestSent={mutatePostFavoriteLoading}
                    errorRefetchCallback={refetchSkillData}
                    isError={isErrorFetchUserSkillData}
                    contentId={userSkillData?.id}
                    videoWatchedCallback={sendContentUsageRequest}
                />
                {userSkillData?.isStandardType && (
                    <GraphCardWrapper id='skill-graph-wrapper'>
                        <SkillGraphCard
                            masterLevelRequested={userSkillData.isMasterFocus}
                            skillProgress={userSkillProgressData as UserSkillProgressDTO}
                            isLoading={isUserSkillProgressLoading}
                            isSkillAssigned={userSkillData?.isAssigned}
                            isProducerMaxLevel={userSkillData?.maxLevel === 'Producer'}
                            isError={isErrorFetchUserSkillProgress}
                            errorRefetchCallback={refetchUserSkillProgress}
                            isLeaderboardActive={isTenantPreferenceActive(ETenantPreference.ENABLE_LEADERBOARD)}
                        />
                    </GraphCardWrapper>
                )}
                <SkillDetailsWrapper>
                    <SkillsInfoPaper elevation={1}>
                        {isLoadingSkillData ? (
                            <Loading />
                        ) : (
                            <AboutSkillContentWrapper>
                                {isErrorFetchUserSkillData ? (
                                    <SomethingWentWrongCard actionCallback={refetchSkillData} />
                                ) : (
                                    <>
                                        {isLgDown ? (
                                            <>
                                                <TabsWrapper>
                                                    <StyledTabs
                                                        value={selectedTabValue}
                                                        onKeyDown={onHandleTabChangeKeyDown}
                                                        onChange={handleTabChange}
                                                    >
                                                        <StyledTab
                                                            isActive={selectedTabValue === 0}
                                                            label={
                                                                <BehaviorsTitleSpanLgDown>
                                                                    {userSkillData?.isStandardType
                                                                        ? t('skill.contentCard.behaviors')
                                                                        : t('skill.contentCard.primaryBehavior')}
                                                                </BehaviorsTitleSpanLgDown>
                                                            }
                                                        />
                                                        <StyledTab
                                                            isActive={selectedTabValue === 1}
                                                            label={
                                                                <ActivitiesTabWrapper>
                                                                    <ActivitiesTitleSpan>
                                                                        {userSkillData?.isStandardType
                                                                            ? t('skill.contentCard.activities')
                                                                            : t('skill.contentCard.requiredResources')}
                                                                    </ActivitiesTitleSpan>
                                                                    <ActivitiesTabCountSpan>
                                                                        {selectedBehavior
                                                                            ? `(${selectedBehavior?.activities.length}/${totalNumberOfActivities})`
                                                                            : `(${totalNumberOfActivities}/${totalNumberOfActivities})`}
                                                                    </ActivitiesTabCountSpan>
                                                                </ActivitiesTabWrapper>
                                                            }
                                                        />
                                                    </StyledTabs>
                                                    {selectedBehavior &&
                                                        selectedBehavior.activities.length <
                                                            totalNumberOfActivities && (
                                                            <SeeAllWrapper>
                                                                <SeeAllTypography
                                                                    variant='overline'
                                                                    onClick={showAllActivitiesHandler}
                                                                >
                                                                    See All
                                                                </SeeAllTypography>
                                                            </SeeAllWrapper>
                                                        )}
                                                </TabsWrapper>
                                                <TabPanel index={0} value={selectedTabValue}>
                                                    <div id='goals-wrapper'>{behaviorsMemo}</div>
                                                </TabPanel>
                                                <TabPanel index={1} value={selectedTabValue}>
                                                    {activitiesRootMemo}
                                                </TabPanel>
                                            </>
                                        ) : (
                                            <BehaviorsActivitiesWrapper
                                                isBestPracticeMode={
                                                    userSkillData?.skillType === ESkillType.BEST_PRACTICE
                                                }
                                            >
                                                <BehaviorsBox id='goals-wrapper'>
                                                    <BehaviorsTitleBox>
                                                        {userSkillData?.isStandardType
                                                            ? t('skill.contentCard.behaviors')
                                                            : t('skill.contentCard.primaryBehavior')}
                                                    </BehaviorsTitleBox>
                                                    {behaviorsMemo}
                                                </BehaviorsBox>
                                                <ActivitiesBox
                                                    className='activities-box'
                                                    isBestPracticeMode={
                                                        userSkillData?.skillType === ESkillType.BEST_PRACTICE
                                                    }
                                                >
                                                    <ActivitiesTabTitleTypography variant='h6'>
                                                        {userSkillData?.isStandardType
                                                            ? t('skill.contentCard.activities')
                                                            : t('skill.contentCard.requiredResources')}
                                                    </ActivitiesTabTitleTypography>
                                                    {activitiesRootMemo}
                                                </ActivitiesBox>
                                            </BehaviorsActivitiesWrapper>
                                        )}
                                    </>
                                )}
                            </AboutSkillContentWrapper>
                        )}
                    </SkillsInfoPaper>
                </SkillDetailsWrapper>
            </RootInnerBox>
        </RootBox>
    );
};

export default Skill;
