import { FC, useEffect, useMemo, useRef } from 'react';
import * as d3 from 'd3';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { isDate, parse } from 'date-fns';
import { ChartBox, RootBox } from './Style';
import { IBarChartItem } from '../../../interfaces/IBarChartItem';
import NoDataCard from '../../../ui/cards/no-data-card/NoDataCard';
import { EAxisType } from '../../../interfaces/enums/EAxisType';
import { formatLocalDate } from '../../../utils/dateUtil';
import { EFormatDate } from '../../../interfaces/enums/EFormatDate';
import Loading from '../loading/Loading';

export interface IChartDimension {
    height?: number;
    width?: number;
    margin?: {
        top?: number;
        right?: number;
        bottom?: number;
        left?: number;
    };
}

export enum EValueSidePosition {
    LEFT = 'left',
    RIGHT = 'right'
}

const defaultDimension: IChartDimension = {
    width: 400,
    height: 300,
    margin: {
        right: 20,
        left: 40,
        bottom: 50,
        top: 40
    }
};

interface IProps {
    chartId: string;
    dimension?: IChartDimension;
    data?: IBarChartItem[];
    isDataEmpty?: boolean;
    xAxisType?: EAxisType;
    isLoading?: boolean;
}

const BarChart: FC<IProps> = ({
    dimension,
    chartId,
    data,
    isDataEmpty = false,
    xAxisType = EAxisType.LINEAR,
    isLoading = false
}) => {
    const { width, height, margin } = useMemo(() => {
        const { width, height, margin }: IChartDimension = {
            ...defaultDimension,
            ...dimension
        };
        return {
            width,
            height,
            margin,
            finalWidth: width! - margin!.left! - margin!.right!,
            finalHeight: height! - margin!.top! - margin!.bottom!
        };
    }, [dimension]);
    const chRef = useRef(null);
    const theme = useTheme();
    const matchesLgDownBreakpoint = useMediaQuery(theme.breakpoints.down('lg'));
    const matches800DownBreakpoint = useMediaQuery(theme.breakpoints.down(800));
    const matchesMdDownBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        return () => {
            d3.selectAll(`#${chartId}`).remove();
        };
    }, []);

    useEffect(() => {
        if (isDataEmpty) d3.selectAll(`#${chartId}`).remove();
    }, [isDataEmpty]);

    useEffect(() => {
        d3.selectAll(chRef.current).remove();
        d3.selectAll(`#${chartId}`).remove();

        if (!data) return;

        if (!width || !height || !margin || !margin.left || !margin.right || !margin.top || !margin.bottom) {
            return;
        }

        let chartDimension = { ...defaultDimension, ...dimension };
        var svg = d3
            .select(chRef.current)
            .append('svg')
            .attr('id', `${chartId}`)
            .attr('width', matchesLgDownBreakpoint ? '100%' : width + margin.left + margin.right)
            .attr('height', chartDimension.height! + chartDimension.margin!.top! + chartDimension.margin!.bottom!)
            .append('g')
            .style('transform', 'translate(5px, 20px)');

        const chart = svg
            .append('g')
            .attr('transform', `translate(${chartDimension.margin?.left}, ${chartDimension.margin?.right})`);

        const domainMaxValue = d3.max(data, (d) => d.value) || 0;
        const yScale = d3
            .scaleLinear()
            .range([chartDimension.height!, 0])
            .domain([0, domainMaxValue === 0 ? 5 : domainMaxValue]);

        let yTickFontSize = '15px';
        if (matchesLgDownBreakpoint) {
            yTickFontSize = '12px';
        }
        if (domainMaxValue && domainMaxValue > 1000) {
            yTickFontSize = '12px';
            if (matchesLgDownBreakpoint) {
                yTickFontSize = '9px';
            }
        }

        const leftAxis = chart.append('g').call(
            d3
                .axisLeft(yScale)
                .tickFormat(d3.format('.0f'))
                .ticks(domainMaxValue > 10 ? 10 : domainMaxValue || 1)
        );
        leftAxis.selectAll('path.domain').attr('display', 'none');
        leftAxis.selectAll('.tick line').attr('display', 'none');
        leftAxis
            .style('font-weight', 600)
            .style('letter-spacing', '0.5px')
            .style('font-size', yTickFontSize)
            .style('color', '#A9A9A9')
            .style('font-family', 'Open Sans');

        const firstDaysOfMonth: number[] = [];
        let previosTickMonth: number | undefined = undefined;

        let fontSize = '11px';
        if (matchesLgDownBreakpoint) fontSize = '9px';
        if (matches800DownBreakpoint) fontSize = '8px';
        if (matchesMdDownBreakpoint) fontSize = '6px';

        const xScale = d3
            .scaleBand()
            .range([0, chartDimension.width!])
            .domain(data.map((d) => d.name))
            .padding(0.2);
        const bottomAxis = chart
            .append('g')
            .attr('transform', `translate(0, ${chartDimension.height})`)
            .call(
                d3
                    .axisBottom(xScale)
                    .ticks(d3.timeWeek)
                    .tickFormat((interval, index) => {
                        const date = parse(interval, 'MM/d', new Date());
                        if (xAxisType === EAxisType.MONTHLY_WITH_WEEKS) {
                            const lastDate = data[data.length - 1].name;
                            const lastMonth = parse(lastDate, 'MM/d', new Date()).getMonth();

                            const month = date.getMonth();
                            if (
                                (firstDaysOfMonth.includes(month) && previosTickMonth === month) ||
                                (lastMonth === month && index < 3)
                            ) {
                                previosTickMonth = month;
                                return '';
                            }
                            previosTickMonth = month;
                            firstDaysOfMonth.push(month);
                            return date.toLocaleString('en-US', { month: 'short' });
                        }
                        return isDate(interval)
                            ? formatLocalDate(date, EFormatDate.DAY_AND_MONTH)
                            : interval.toString();
                    })
                    .tickSize(0)
                    .tickPadding(14)
            )
            .style('font-family', 'Open Sans')
            .style('font-size', fontSize)
            .style('font-weight', 600)
            .style('letter-spacing', '0.5px')
            .style('color', '#8C8C8C');
        bottomAxis.selectAll('.tick line').attr('display', 'none');
        d3.selectAll('svg .domain').attr('stroke', '#616063').attr('stroke-width', 2);

        svg.selectAll('svg g.tick line').attr('x2', null);
        svg.selectAll('svg g.tick line').attr('y2', null);

        chart
            .selectAll()
            .data<IBarChartItem>(data)
            .enter()
            .append('rect')
            .attr('x', (s, i) => xScale(s.name) || '')
            .attr('y', chartDimension.height! - 4)
            .attr('id', (_, index) => 'bar-chart-subitem-' + index)
            .attr('height', '4px')
            .attr('width', xScale.bandwidth())
            .attr('fill', theme.palette.primary.main);

        chart
            .selectAll()
            .data<IBarChartItem>(data.filter((d) => d.value !== undefined))
            .enter()
            .append('rect')
            .attr('rx', '4px')
            .attr('x', (s) => xScale(s.name) || '')
            .attr('y', (s) => yScale(s.value!))
            .attr('id', (_, index) => 'bar-chart-item-' + index)
            .attr('class', 'bar-chart-item')
            .attr('height', (s) => chartDimension.height! - yScale(s.value!))
            .attr('width', xScale.bandwidth())
            .attr('fill', theme.palette.primary.main)
            .on('mouseover', (d) => {
                try {
                    const g = chart.append('g');
                    const x = parseInt(d.target.getAttribute('x')) + d.target.getAttribute('width') / 2;
                    const y = parseInt(d.target.getAttribute('y')) - 20;
                    g.attr('transform', function (s, i) {
                        return 'translate(' + x + ',' + y + ')';
                    });
                    g.append('circle')
                        .attr('id', `circle-${d.target.id}`)
                        .attr('r', 10)
                        .attr('fill', theme.palette.primary.main)
                        .attr('stroke', '#44356F')
                        .attr('stroke-width', '2px');
                    g.append('text')
                        .attr('text-anchor', 'middle')
                        .attr('fill', theme.palette.common.white)
                        .attr('dy', 3)
                        .style('font-family', 'Ubuntu')
                        .style('font-size', domainMaxValue > 1000 ? '8px' : '11px')
                        .style('font-weight', 700)
                        .style('letter-spacing', '0.5px')
                        .text(d.target.__data__.value);
                    svg.select(`#${d.target.id}`).style('stroke-width', '2px').style('stroke', '#000000CC');
                } catch (err) {
                    console.error(err);
                }
            })
            .on('mouseout', (d) => {
                svg.select(`#circle-${d.target.id}`).remove();
                svg.select(`#${d.target.id}`).style('stroke-width', '0px');
            });
    }, [chartId, data, isDataEmpty, isLoading, width, height]);

    const contentMemo = useMemo(() => {
        if (isDataEmpty && !isLoading) return <NoDataCard boxStyle={{ minHeight: '500px', boxShadow: 'none' }} />;
        if (isLoading) return <Loading boxStyle={{ width: '100%' }} />;
        return <ChartBox ref={chRef} />;
    }, [isDataEmpty, isLoading]);

    return <RootBox>{contentMemo}</RootBox>;
};

export default BarChart;
