import { FC } from 'react';

const SkillBestPracticeUnassigned: FC = () => {
    return (
        <svg
            id='graph-svgbp-unassigned-8-0'
            width='100'
            height='92'
            fill='rgb(229, 229, 229)'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g id='doughnut-chart-bp-unassigned-8-0'>
                <g className='donut-container' transform='translate(40, 40)'>
                    <path
                        d='M0,-34A34,34,0,1,1,0,34A34,34,0,1,1,0,-34M0,-16A16,16,0,1,0,0,16A16,16,0,1,0,0,-16Z'
                        stroke='rgb(0, 0, 0)'
                        strokeWidth='2'
                        fill='rgb(229, 229, 229)'
                    ></path>
                </g>
                <circle id='colored-fill' r='15' cx='40' cy='40' strokeWidth='0' fill='rgb(255, 255, 255)'></circle>
                <g id='value' transform='translate(29, 44)' fontSize='14' fontWeight='700' color='#8C8C8C'>
                    <text fontFamily='"Open Sans", Arial, sans-serif' fill='#8C8C8C' letterSpacing='0.25px'>
                        0/8
                    </text>
                </g>
                <g className='donut-container' transform='translate(40, 40)'>
                    <path
                        d='M0,-33L0,-17Z'
                        id='main-gbp-unassigned-8-0'
                        stroke='rgb(0, 0, 0)'
                        strokeWidth='0'
                        fill='rgb(140, 140, 140)'
                    ></path>
                    <path
                        d='M0,-33A33,33,0,1,1,0,33A33,33,0,1,1,0,-33M0,-17A17,17,0,1,0,0,17A17,17,0,1,0,0,-17Z'
                        id='main-gbp-unassigned-8-0'
                        stroke='rgb(0, 0, 0)'
                        strokeWidth='0'
                        fill='rgb(229, 229, 229)'
                    ></path>
                </g>
            </g>
        </svg>
    );
};

export default SkillBestPracticeUnassigned;
