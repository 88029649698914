import { EGraphCardSelect } from '../interfaces/enums/EGraphCardSelect';
import { useState } from 'react';

interface IUseInsightsTimelineOption {
    handleSelectedTimespanOptionChange: (value: string | string[]) => void;
    selectedTimespanOption: EGraphCardSelect;
}

interface IUseInsightsTimelineOptionProps {
    initialTimespanOptionValue: EGraphCardSelect;
    timespanOptionValueChangeCallback?: (value: EGraphCardSelect) => void;
}

const useInsightsTimespanOption: (props: IUseInsightsTimelineOptionProps) => IUseInsightsTimelineOption = (props) => {
    const { initialTimespanOptionValue, timespanOptionValueChangeCallback } = props;

    const [timespanOptionValue, setTimespanOptionValue] = useState<EGraphCardSelect>(
        initialTimespanOptionValue || EGraphCardSelect.MONTH_1
    );

    const handleSelectedTimespanOptionChange = (value: string | string[]) => {
        if (timespanOptionValueChangeCallback) timespanOptionValueChangeCallback(value as EGraphCardSelect);
        setTimespanOptionValue(value as EGraphCardSelect);
    };

    return {
        selectedTimespanOption: timespanOptionValue,
        handleSelectedTimespanOptionChange
    };
};

export default useInsightsTimespanOption;
